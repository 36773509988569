import React, { Component } from "react";
//import { Trans } from 'react-i18next';
import Button from "react-bootstrap/Button";
import Modal from "react-modal";
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import { Image, Video, Transformation } from "cloudinary-react";
import { dateFormatterTimeAndDay } from "../helpers/dateFormatter.js"
import styles from "./GalleryModal.module.scss";

export default class GalleryModal extends Component {
    constructor() {
        super();
        this.state = {
            meetingNote: "",
            selectedMeetingSlot: null,
        };
        this.handleSlotChange = this.handleSlotChange.bind(this);
    }

    // Called when user selects a meeting slot
    handleSlotChange = (value) => {
        // Update database here to check slot is still available
        // Closure complication here
        this.props.loadCurrentDatabaseContent();
        this.setState({
            selectedMeetingSlot: value,
        });
    };

    // Called when user requests a meeting
    handleMeetingRequestSubmit = () => {
        console.log("Submitting meeting request: " + this.state.meetingNote);

        // Check that a meeting slots has been selected
        if (this.state.selectedMeetingSlot != null) {

            fetch("/api/submitMeetingRequest", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    meetingRequesteeUser: this.props.meetingRequesteeUser,
                    meetingNote: this.state.meetingNote,
                    selectedMeetingSlot: this.state.selectedMeetingSlot,
                }),
            })
                .then((result) => result.text())
                .then((response) => {
                    console.log("Submitted meeting request");
                });

            alert("Your meeting request has been submitted.");
            // Clear the selection
            this.setState({ selectedMeetingSlot: null });

        } else {
            alert("Please select a meeting slot");
        }

        // Close modal
        //this.handleMeetingRequestClose();
    };

    render() {
        const meetingSlotOptionsFiltered = this.props.meetingSlots.filter(
            (meetingSlot) => {
                var slotAvailable = true;
                const meetingSlotIDBeingChecked = meetingSlot._id;
                // Check through existing user meetings where user is requestor
                if (this.props.existingUserMeetings.meetingRequestsMade) {
                    this.props.existingUserMeetings.meetingRequestsMade.forEach(
                        (meetingRequest, index) => {
                            if (
                                meetingRequest.meetingStatus !== "declined" &&
                                meetingRequest.meetingSlotID._id === meetingSlotIDBeingChecked
                            ) {
                                slotAvailable = false;
                            }
                        }
                    );
                }
                // Check through existing user meetings already booked where user is requestee
                if (this.props.existingUserMeetings.meetingRequestsReceived) {
                    this.props.existingUserMeetings.meetingRequestsReceived.forEach(
                        (meetingRequest, index) => {
                            if (
                                meetingRequest.meetingStatus !== "declined" &&
                                meetingRequest.meetingSlotID._id === meetingSlotIDBeingChecked
                            ) {
                                slotAvailable = false;
                            }
                        }
                    );
                }
                // Check requestee availability
                // Check through requestee meetings where requestee is requestor
                if (this.props.meetingRequesteeUserMeetings.meetingRequestsMade) {
                    this.props.meetingRequesteeUserMeetings.meetingRequestsMade.forEach(
                        (meetingRequest, index) => {
                            if (
                                meetingRequest.meetingStatus !== "declined" &&
                                meetingRequest.meetingSlotID._id === meetingSlotIDBeingChecked
                            ) {
                                slotAvailable = false;
                            }
                        }
                    );
                }
                // Check through requestee meetings already booked where requetee is requestee
                if (this.props.meetingRequesteeUserMeetings.meetingRequestsReceived) {
                    this.props.meetingRequesteeUserMeetings.meetingRequestsReceived.forEach(
                        (meetingRequest, index) => {
                            if (
                                meetingRequest.meetingStatus !== "declined" &&
                                meetingRequest.meetingSlotID._id === meetingSlotIDBeingChecked
                            ) {
                                slotAvailable = false;
                            }
                        }
                    );
                }
                return slotAvailable;
            }
        );

        const meetingSlotOptions = meetingSlotOptionsFiltered.map((meetingSlot) => {
            return (
                <ToggleButton
                    key={meetingSlot._id}
                    variant="primary"
                    className={styles.meetingSlotsButton}
                    value={meetingSlot._id}
                >
                    {dateFormatterTimeAndDay(meetingSlot.meetingSlotTimestamp, this.props.userProfile)}
                </ToggleButton>
            );
        });

        return (
            <Modal
                isOpen={this.props.showMeetingRequestModal}
                ariaHideApp={false}
                onRequestClose={this.props.handleMeetingRequestClose}
                contentLabel="Attendee Modal"
                overlayClassName={styles.modalOverlay}
                className={styles.modalContent}
            >
                {this.props.meetingRequesteeUser ?
                    <div className={styles.modalFlexboxColumn}>
                        <div className={styles.modalTitleBar} style={{ backgroundImage: 'url(' + window.$videoPath + 'cornerNav/modalHeader.png)', }}>
                            <h1 className={styles.modalPageTitle}> {this.props.meetingRequesteeUser &&
                                this.props.meetingRequesteeUser.firstName}{" "}
                                {this.props.meetingRequesteeUser &&
                                    this.props.meetingRequesteeUser.surname}</h1>
                            <button
                                className={styles.modalClose}
                                variant="success"
                                onClick={this.props.handleMeetingRequestClose}
                                aria-label="Close"
                            >
                                Close
                                <img
                                    className={styles.modalCloseIcon}
                                    src="/icons/close-no-circle.png"
                                    alt="Close"
                                />
                            </button>
                        </div>
                        <div className={styles.scrollableArea}>
                            <div className={styles.columnContainer}>
                                <div className={styles.leftProfileColumn}>
                                    <div className={styles.profilePictureAndTextFieldsDiv}>
                                        {this.props.meetingRequesteeUser &&
                                            this.props.meetingRequesteeUser.userProfilePictureID ? (
                                            <div className={styles.profilePictureArea}>
                                                <img
                                                    className={styles.profilePictureBackground}
                                                    src={window.$videoPath + "icons/profilePictureBackground.png"}
                                                    alt="Detail"
                                                />
                                                <Image
                                                    className={styles.profilePicture}
                                                    cloudName="oijqec973f1nf"
                                                    publicId={this.props.meetingRequesteeUser.userProfilePictureID + ".png"}
                                                    width="240"
                                                    height="240"
                                                    secure={true}
                                                >
                                                    <Transformation
                                                        height="240"
                                                        width="240"
                                                        gravity="face"
                                                        crop="fill"
                                                        radius="max"
                                                    />
                                                </Image>
                                            </div>
                                        ) : null}
                                        <div className={styles.textAnswerFieldsDiv}>
                                            {this.props.meetingRequesteeUser.jobTitle &&
                                                this.props.meetingRequesteeUser.jobTitle.length > 1 ? (
                                                <span>
                                                    <p className={styles.profileParameterTitles}>Job title:</p>
                                                    <p className={styles.profileParameterAnswer}>
                                                        {this.props.meetingRequesteeUser.jobTitle}
                                                        <br />
                                                    </p>
                                                </span>
                                            ) : null}

                                            {this.props.meetingRequesteeUser.country &&
                                                this.props.meetingRequesteeUser.country.length > 1 ? (
                                                <span>
                                                    <p className={styles.profileParameterTitles}>Country:</p>
                                                    <p className={styles.profileParameterAnswer}>
                                                        {this.props.meetingRequesteeUser.country}
                                                        <br />
                                                    </p>
                                                </span>
                                            ) : null}

                                            {this.props.meetingRequesteeUser.company &&
                                                this.props.meetingRequesteeUser.company.length > 1 ? (
                                                <span>
                                                    <p className={styles.profileParameterTitles}>Company:</p>
                                                    <p className={styles.profileParameterAnswer}>
                                                        {this.props.meetingRequesteeUser.company}
                                                        <br />
                                                    </p>
                                                </span>
                                            ) : null}
                                        </div>
                                    </div>

                                    <br />

                                    {this.props.meetingRequesteeUser && this.props.meetingRequesteeUser.areasOfInterest && this.props.meetingRequesteeUser.areasOfInterest.length > 0 ?
                                        <h4 className={styles.profileParameterTitles}>
                                            About{" "}
                                            {this.props.meetingRequesteeUser.firstName}{" "}
                                            {this.props.meetingRequesteeUser.surname}
                                        </h4> : null
                                    }
                                    <p className={styles.profileParameterAnswer}>
                                        {this.props.meetingRequesteeUser.areasOfInterest}
                                    </p>
                                </div>


                                <div className={styles.rightProfileColumn}>
                                    {this.props.meetingRequesteeUser ? (
                                        this.props.meetingRequesteeUser.userProfileVideoApproved &&
                                            this.props.meetingRequesteeUser.userProfileVideoID ? (
                                            <div>
                                                <h3>Watch my video</h3>
                                                <Video
                                                    controls={true}
                                                    cloudName="oijqec973f1nf"
                                                    publicId={
                                                        this.props.meetingRequesteeUser.userProfileVideoID
                                                    }
                                                    width="400"
                                                ></Video>
                                            </div>
                                        ) : null
                                    ) : null}
                                    <br />
                                    {(this.props.userProfile) ?
                                        <span>
                                            <h4 className={styles.requestMeetingTitle}>
                                                Request a meeting with:<br />
                                                {this.props.meetingRequesteeUser &&
                                                    this.props.meetingRequesteeUser.firstName}{" "}
                                                {this.props.meetingRequesteeUser &&
                                                    this.props.meetingRequesteeUser.surname}
                                            </h4>
                                            <div className={styles.meetingBookingDiv}>
                                                For a quick chat please select one of the time slots
                                                {/**For a 10 minute meeting please select one of the time slots*/}
                                                below and click submit. Once accepted meetings will show in
                                                your agenda.<br /><br />To see your meeting requests or requested meeting
                                                status go to ‘MY MEETINGS’
                                                <br />
                                                <ToggleButtonGroup
                                                    type="radio"
                                                    value={this.state.selectedMeetingSlot}
                                                    onChange={this.handleSlotChange}
                                                    name="selectedMeetingSlot"
                                                    className={styles.meetingSlotsList}
                                                >
                                                    {meetingSlotOptions}
                                                    {meetingSlotOptionsFiltered.length === 0 ? <p><strong><br />No meeting slots available for this attendee</strong></p> : null}
                                                </ToggleButtonGroup>
                                                <br />
                                                <br />
                                                <Button
                                                    onClick={this.handleMeetingRequestSubmit}
                                                    style={{ float: "right" }}>
                                                    Submit
                                                </Button>
                                            </div>
                                        </span> : null}
                                </div>
                            </div>
                            <br />
                            <br />
                        </div>
                    </div>
                    : null}
            </Modal>
        );
    }
}
