import React, { Component } from "react";
import { CSSTransition } from "react-transition-group";
import { Link } from "react-router-dom";
import styles from "./Lobby4.module.scss";

export default class Lobby extends Component {
  constructor(props) {
    super(props);
    this.state = {
      introVideoComplete: false,
    };
  }

  componentDidMount () {
    const { cookies } = this.props;
    const lobbyVisitedCookie = cookies.get("lobbyVisited");
    if (lobbyVisitedCookie === undefined) {
      this.props.setShowHelpModal(true);
      cookies.set("lobbyVisited", "yes", { path: "/" });
    }

    // Start timeout in case video does not load
    setTimeout(
      function () {
        this.setState({ introVideoComplete: true });
      }.bind(this),
      6000
    );

    //alert("Welcome to The Warehouse Environment, feel free to self-explore although some areas are for demonstration purposes only.")
  }

  render () {
    return (
      <div>
        <div className="imageNavDiv">
          <video
            className="imageNavBackground"
            autoPlay
            muted
            id="introVideo"
            onEnded={() => this.setState({ introVideoComplete: true })}
          >
            <source src={window.$videoPath + "demoVenues/warehouse/altLobby4.webm"} type="video/webm" />
            <source src={window.$videoPath + "demoVenues/warehouse/altLobby4.mp4"} type="video/mp4" />
          </video>

          <CSSTransition
            in={this.state.introVideoComplete}
            timeout={1500}
            classNames="imageNavBackground"
            unmountOnExit
          >
            <img
              className="imageNavBackground"
              src={window.$videoPath + "demoVenues/warehouse/altLobby4.jpg"}
              alt="Background"
            ></img>
          </CSSTransition>

          {/** The below is to immediately hide the img if the filename goes to blank otherwise shows missing file whilst fading out */}
          {this.props.lobbyScreen && this.props.lobbyScreen.length > 0 ? (
            <CSSTransition
              in={
                this.state.introVideoComplete &&
                this.props.lobbyScreen &&
                this.props.lobbyScreen.length > 0
              }
              timeout={1500}
              classNames="imageNavBackground"
              unmountOnExit
            >
              <video className={styles.lobbyVideoScreen} autoPlay muted loop>
                <source
                  src={
                    window.$videoPath +
                    "lobbyScreen/" +
                    this.props.lobbyScreen +
                    "2.webm"
                  }
                  type="video/webm"
                />
                <source
                  src={
                    window.$videoPath +
                    "lobbyScreen/" +
                    this.props.lobbyScreen +
                    "2.mp4"
                  }
                  type="video/mp4"
                />
              </video>
            </CSSTransition>
          ) : null}


          <Link to="/plenary4">
            <img
              className={`${styles.hoverHotspot} ${styles.plenaryHoverHotspot}`}
              src="/hoverShapes/hoverSquare.png"
              alt="To plenary"
            ></img>
          </Link>

          <Link to="/breakouts4">
            <img
              className={`${styles.hoverHotspot} ${styles.breakoutsHoverHotspot}`}
              src="/hoverShapes/hoverSquare.png"
              alt="To breakouts"
            ></img>
          </Link>

          <Link to="/networking4">
            <img
              className={`${styles.hoverHotspot} ${styles.networkingHoverHotspot}`}
              src="/hoverShapes/hoverSquare.png"
              alt="To networking"
            ></img>
          </Link>

          <Link to="/visualOndemand">
            <img
              className={`${styles.hoverHotspot} ${styles.onDemandHoverHotspot}`}
              src="/hoverShapes/hoverSquare.png"
              alt="To on demands"
            ></img>
          </Link>

          <img
            className={`${styles.hoverHotspot} ${styles.helpDeskHotSpot}`}
            src="/hoverShapes/hoverSquare.png"
            alt="Help Desk"
            onClick={() => this.props.setShowHelpModal(true)}
          ></img>
        </div>
      </div>
    );
  }
}
