import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import PosterModal from "./PosterModal";
import { requestPoints } from "./../helpers/requestPoints.js";
import styles from "./PostersMobile.module.scss";

export default class PostersMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPosterModal: false,
      selectedPoster: null,
    };
  }

  componentDidMount () {
    requestPoints("visitPostersMobile", "visitPostersMobile");
  }

  showPoster (poster) {
    requestPoints("viewPoster", poster._id);
    this.setState({ selectedPoster: poster, showPosterModal: true });
    this.setState({ showPrimaryTab: true });
  }

  render () {
    const posterList = this.props.posterGalleryContent.map(
      (posterGalleryItem) => {
        return (
          <div
            key={posterGalleryItem._id}
            className={styles.mobileListItem}
            onClick={() => this.showPoster(posterGalleryItem)}
          >
            <h3 className={styles.mobileListTitle}>{posterGalleryItem.posterName}</h3>
          </div>
        );
      }
    );

    return (
      <div className={styles.mobileListView} >
        <div className={styles.mobileTitleArea}>
          <h1 className={styles.mobileTitleText}>POSTERS</h1>
          <Link to="/lobby"><Button>Back to Lobby</Button></Link>
        </div>


        <PosterModal
          showPosterModal={this.state.showPosterModal}
          closePosterModal={() => this.setState({ showPosterModal: false })}
          poster={this.state.selectedPoster}
        />

        {posterList}

      </div>
    );
  }
}
