import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import styles from "./LoginHelp.module.scss";
import preLoginStyles from "./Pre-Login.module.scss";

export default class LoginHelp extends Component {
  render() {
    return (
      <div className={styles.loginHelpModal}>
        <div className={styles.loginHelpModalInnerDiv}>
          <img
            className={preLoginStyles.preLoginBoxLogo}
            style={{ width: "30%" }}
            src={window.$videoPath + "logo.png"}
            alt="Logo"
          />
          <div className={styles.loginHelpTextArea}>
            <p>
              <strong>Platform opening</strong>
              <br />
              The live event will take place on Thursday 29 April.<br />
              The platform is now open to set up your account profile and select your preferred session time, please add this to your calendar and come back to the event site on Thursday 29 April.
              <br />
              <br />
              <strong>Account set up</strong>
              <br />
              To set up an account, select New User? Setup Account below and enter your profile information. You will receive a verification email which you will need to authorise to gain access to Beehive Open House.
              <br />
              <br />
              <strong>Forgot password?</strong>
              <br />
              Select the Forgotten Password button below. Enter your email address and select the Reset Password button - if you have set up an account, you will receive a password reset email. If you haven’t yet set up an account, please follow the steps above.
              <br />
              <br />
              <strong>Trouble accessing the site?</strong>
              <br />
              Please check your email and/or password are correct. If you have not set up an account yet please click below to set one up.
              <br />
              <br />
              <strong>Further support</strong>
              <br />
              For further support, please contact <a href="mailto:enquiries@liveunion.co.uk">enquiries@liveunion.co.uk</a>
            </p>
            <br />

            <button
              onClick={() => this.props.handleCloseLoginHelpDiv()}
              className={preLoginStyles.highlightedButton}
              style={{position: "absolute", top: "5%", right: "5%"}}
            >
              Back
            </button>
            <br />
          </div>
        </div>
      </div>
    );
  }
}
