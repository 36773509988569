import React, { Component } from "react";
import { Image, Video, Transformation } from "cloudinary-react";
//import TimezoneSelect from 'react-timezone-select'
import Modal from "react-modal";
import styles from "./UserProfile.module.scss";
import userProfileAndRegistration from "./UserProfileAndRegistration.module.scss";

export default class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      surname: "",
      jobTitle: "",
      country: "",
      company: "",
      areasOfInterest: "",
      email: "",

      userProfilePictureID: null,
      fileInputDisabled: false,

      userLanguage: "en",
      userTimezone: { value: "Europe/London" },
      userAgeBracket: 2,

      page1Active: true,
      page1Complete: false,
      page2Active: false,
      page2Complete: false,
      page3Active: false,
      page3Complete: false,
      page4Active: false,
      page4Complete: false,
      page5Active: false,
    };

    this.profileScrollRef = React.createRef();
  }

  componentDidMount() {
    this.retrieveUserDetails();
  }

  retrieveUserDetails = () => {
    fetch("/api/getUserProfile")
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.status);
        } else {
          return res.json();
        }
      })
      .then((data) => this.loadResponseIntoState(data))
      .catch((error) => {
        console.log("Unable to load user profile");
      });
  };

  loadResponseIntoState(data) {
    this.setState({
      firstName: data.user.firstName,
      surname: data.user.surname,
      jobTitle: data.user.jobTitle,
      country: data.user.country,
      company: data.user.company,
      areasOfInterest: data.user.areasOfInterest,
      email: data.user.email,
      userProfilePictureID: data.user.userProfilePictureID,
      userLanguage: data.user.userLanguage,
      userTimezone: data.user.userTimezone,
      userAgeBracket: data.user.userAgeBracket,

      // Reset dialogue status too
      page1Active: true,
      page1Complete: false,
      page2Active: false,
      page2Complete: false,
      page3Active: false,
      page3Complete: false,
      page4Active: false,
      page4Complete: false,
      page5Active: false,
    });
  }

  handleProfilePictureUpload = (event) => {
    this.setState({ fileInputDisabled: true });
    const data = new FormData();
    data.append("file", event.target.files[0]);

    fetch("/api/uploadImage", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          userProfilePictureID: data.publicID,
        });
        console.log("Returned upload ID: " + this.state.userProfilePictureID);
        this.setState({ fileInputDisabled: false });
      });
  };

  handleProfileVideoUpload = (event) => {
    this.setState({ fileInputDisabled: true });
    const data = new FormData();
    data.append("file", event.target.files[0]);

    fetch("/api/uploadVideo", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          userProfileVideoID: data.publicID,
        });
        console.log("Returned upload ID: " + this.state.userProfilePictureID);
        this.setState({ fileInputDisabled: false });
      });
  };


  handleChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  saveFormData = () => {
    fetch("/api/updateUserProfile", {
      method: "POST",
      body: JSON.stringify(this.state),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 200) {
          console.log("Saved successfully");
        } else {
          const error = new Error(res.error);
          throw error;
        }
      })
      .catch((err) => {
        console.error(err);
        alert("Error updating profile -  please try again");
      });
  };

  nextPage1 = () => {
    if (this.state.email && this.state.email.length > 0) {
      this.saveFormData();
      this.profileScrollRef.scrollTop = 0;
      this.setState({ page1Active: false, page2Active: true });
      if (
        this.state.firstName.length > 0 &&
        this.state.surname.length > 0 &&
        this.state.jobTitle.length > 0 &&
        this.state.country.length > 0 &&
        this.state.company.length > 0 &&
        this.state.areasOfInterest.length > 0
      ) {
        this.setState({ page1Complete: true });
      } else {
        this.setState({ page1Complete: false });
      }
    } else {
      alert("Email cannot be blank")
    }
  };

  nextPage2 = () => {
    this.saveFormData();
    this.profileScrollRef.scrollTop = 0;
    this.setState({ page2Active: false, page3Active: true });
    if (
      this.state.userProfilePictureID &&
      this.state.userProfilePictureID.length > 0 //&&
      //this.state.userProfileVideoID &&
      //this.state.userProfileVideoID.length > 0
    ) {
      this.setState({ page2Complete: true });
    } else {
      this.setState({ page2Complete: false });
    }
  };

  closeAndResetProfileModal = () => {
    this.setState({ page1Complete: false });
  };

  render() {
    return (
      <Modal
        onAfterOpen={this.retrieveUserDetails}
        isOpen={this.props.showProfileModal}
        ariaHideApp={false}
        contentLabel="User Profile"
        //contentRef={(contentDiv) => (this.profileScrollRef = contentDiv)}
        overlayClassName={styles.modalOverlay}
        className={styles.modalContent}
        style={{ content: { backgroundImage: "url(" + window.$videoPath + "cornerNav/modalBackground.png)" } }}
      >
        <div className={styles.modalFlexboxColumn}>
          <div className={styles.modalTitleBar} style={{ backgroundImage: 'url(' + window.$videoPath + 'cornerNav/modalHeader.png)', }}>
            <h1>My Profile</h1>
          </div>

          <div className={styles.scrollableArea} ref={(contentDiv) => (this.profileScrollRef = contentDiv)}>
            <div className={styles.profileEntryArea}>
              <br />
              {/*Page 1 */}
              {this.state.page1Active ? (
                <div>
                  <div className={userProfileAndRegistration.profileGridContainer}>

                    <div className={userProfileAndRegistration.profileGridElement}>
                      <label htmlFor="firstName" className={userProfileAndRegistration.formLabel}>
                        First name:
                      </label>
                      <input
                        className={`${userProfileAndRegistration.formField} ${styles.textEntry}`}
                        type="text"
                        name="firstName"
                        placeholder="First name"
                        value={this.state.firstName}
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className={userProfileAndRegistration.profileGridElement}>
                      <label htmlFor="surname" className={userProfileAndRegistration.formLabel}>
                        Surname:
                      </label>
                      <input
                        className={`${userProfileAndRegistration.formField} ${styles.textEntry}`}
                        type="text"
                        name="surname"
                        placeholder="Surname"
                        value={this.state.surname}
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className={userProfileAndRegistration.profileGridElement}>
                      <label htmlFor="jobTitle" className={userProfileAndRegistration.formLabel}>
                        Job title:
                      </label>
                      <input
                        className={`${userProfileAndRegistration.formField} ${styles.textEntry}`}
                        type="text"
                        name="jobTitle"
                        placeholder="Job title"
                        value={this.state.jobTitle}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className={userProfileAndRegistration.profileGridElement}>
                      <label htmlFor="company" className={userProfileAndRegistration.formLabel}>
                        Company:
                      </label>
                      <input
                        className={`${userProfileAndRegistration.formField} ${styles.textEntry}`}
                        type="text"
                        name="company"
                        placeholder="Company/Affiliation"
                        value={this.state.company}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className={userProfileAndRegistration.profileGridElement}>
                      <label htmlFor="areasOfInterest" className={userProfileAndRegistration.formLabel}>
                        Areas of interest:
                      </label>
                      <input
                        className={`${userProfileAndRegistration.longFormField} ${styles.textEntry}`}
                        type="text"
                        name="areasOfInterest"
                        placeholder="Areas of Interest"
                        value={this.state.areasOfInterest}
                        onChange={this.handleChange}
                      />
                    </div>


                    <div className={userProfileAndRegistration.profileGridElement}>
                      <label htmlFor="country" className={userProfileAndRegistration.formLabel}>
                        Country:
                      </label>
                      <input
                        className={`${userProfileAndRegistration.formField} ${styles.textEntry}`}
                        type="text"
                        name="country"
                        placeholder="Country"
                        value={this.state.country}
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className={userProfileAndRegistration.profileGridElement}>
                      <label htmlFor="email" className={userProfileAndRegistration.formLabel}>
                        Email:
                      </label>
                      <input
                        className={`${userProfileAndRegistration.formField} ${styles.textEntry}`}
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={this.state.email}
                        onChange={this.handleChange}
                      />
                    </div>
                    {/**<div className={userProfileAndRegistration.profileGridElement}>
                      <label htmlFor="userLanguage" className={userProfileAndRegistration.formLabel}>
                        Preferred Language:
                      </label>
                      <select
                        className={`${userProfileAndRegistration.formField} ${styles.dropDownEntry}`}
                        id="userLanguage"
                        name="userLanguage"
                        placeholder="Preferred Language"
                        tabIndex="90"
                        value={this.state.userLanguage}
                        onChange={this.handleChange}
                      >
                        <option value="en">English</option>
                        <option value="es">Spanish</option>
                        <option value="fr">French</option>
                        <option value="zh">Mandarin</option>
                      </select>
                    </div>

                       <div className={userProfileAndRegistration.profileGridElement}>
                      <label htmlFor="userAgeBracket" className={userProfileAndRegistration.formLabel}>
                        Age:
                      </label>
                      <select
                        className={`${userProfileAndRegistration.formField} ${styles.dropDownEntry}`}
                        id="userAgeBracket"
                        name="userAgeBracket"
                        placeholder="Age"
                        tabIndex="90"
                        value={this.state.userAgeBracket}
                        onChange={this.handleChange}
                      >
                        <option value="1">16/17</option>
                        <option value="2">18+</option>
                      </select>
                    </div>

                       <div className={userProfileAndRegistration.profileGridElement}>
                      <label htmlFor="userTimezone" className={userProfileAndRegistration.formLabel}>
                        Timezone:
                      </label>
                      <TimezoneSelect
                        id="userTimezone"
                        value={this.state.userTimezone.value}
                        onChange={(data) => this.setState({ userTimezone: data })} />
                    </div>*/}
                  </div>


                  {/**<div className={styles.buttonArea}>
                    <Button
                      onClick={this.nextPage1}
                      className={`${"btn btn-success"} ${styles.bottomRightButtons}`}
                    >
                      Next
                    </Button>
                    <Button
                      onClick={this.props.closeProfileModal}
                      className={`${"btn btn-primary"} ${styles.bottomRightButtons}`}
                    >
                      Close without saving
                    </Button>
                  </div>*/}

                  <div className={styles.nextBackButtonsRow}>
                    <button
                      className={styles.button}
                      onClick={this.props.closeProfileModal}
                      type="button"
                    >
                      Close
                    </button>
                    <div style={{ flex: 1 }} />
                    <button
                      className={styles.highlightedButton}
                      onClick={this.nextPage1}
                      disabled={this.state.fileInputDisabled}
                    >
                      Next
                    </button>
                  </div>

                </div>
              ) : null}

              {/*Page 2 */}
              {this.state.page2Active ? (
                <div>
                  <Image
                    cloudName="oijqec973f1nf"
                    publicId={this.state.userProfilePictureID}
                    width="150"
                    secure={true}
                    style={{ float: "right" }}
                  >
                    <Transformation
                      height="150"
                      width="150"
                      crop="fill"
                      radius="20"
                    />
                  </Image>
                  <p className={styles.subheading}>
                    Profile picture and video upload
                  </p>

                  <label
                    className={styles.formLabel}
                    htmlFor="profilePicture"
                    style={{ display: 'block' }}
                  >
                    Picture upload (JPEG, PNG, GIF)
                  </label>
                  <label className={styles.customFileUpload}>
                    <div className={styles.highlightedButton} style={{ textAlign: 'center' }}>Upload</div>
                    <input
                      className={styles.fileInput}
                      name="profilePicture"
                      type="file"
                      disabled={this.state.fileInputDisabled}
                      accept="image/*"
                      onChange={this.handleProfilePictureUpload}
                    />
                  </label>

                  <div className={styles.profileVideoSelection}>
                    <label className={styles.formLabel} htmlFor="profileVideo">
                      Video upload
                    </label>
                    <p className={styles.introText}>
                      Please upload a video (up to 30 secs) telling people a bit
                      about yourself.
                      <br />
                      <br />
                      Feel free to be as creative as you like with this!
                      MP4/WMV/MOV video files are all good
                    </p>
                    <label className={styles.customFileUpload}>
                      <img
                        className={styles.uploadButton}
                        src={window.$videoPath + "icons/profile/upload.png"}
                        alt="Upload"
                      ></img>
                      <input
                        className={styles.fileInput}
                        name="profileVideo"
                        type="file"
                        disabled={this.state.fileInputDisabled}
                        accept="video/*"
                        onChange={this.handleProfileVideoUpload}
                      />
                    </label>
                    {this.state.userProfileVideoID != null ? (
                      <div>
                        <Video
                          controls={true}
                          cloudName="oijqec973f1nf"
                          publicId={this.state.userProfileVideoID}
                          width="200"
                        ></Video>
                      </div>
                    ) : null}
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />


                  <div className={styles.nextBackButtonsRow}>
                    <button
                      className={styles.button}
                      onClick={this.props.closeProfileModal}
                      type="button"
                    >
                      Close
                    </button>
                    <div style={{ flex: 1 }} />
                    <button
                      className={styles.highlightedButton}
                      onClick={this.nextPage2}
                      disabled={this.state.fileInputDisabled}
                    >
                      Next
                    </button>
                  </div>

                </div>
              ) : null}

              {/*Page 3*/}
              {this.state.page3Active ? (
                <div>
                  <p className={styles.subheading}></p>
                  <p className={styles.subheading}>
                    Many thanks for completing your profile.
                  </p>
                  <br />
                  <br />
                  <br />
                  <br />

                  <div className={styles.nextBackButtonsRow}>
                    <div style={{ flex: 1 }} />
                    <button
                      className={styles.highlightedButton}
                      onClick={this.props.closeProfileModal}
                      disabled={this.state.fileInputDisabled}
                    >
                      Submit
                    </button>
                  </div>

                </div>
              ) : null}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
