import React, { Component } from "react";
import { CSSTransition } from "react-transition-group";
import { Link } from "react-router-dom";
import { requestPoints } from "../../../helpers/requestPoints.js";
import styles from "./Networking4.module.scss";

export default class Networking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      introVideoComplete: false,
    };
  }

  componentDidMount () {
    requestPoints("visitNetworkingLounge", "visitNetworkingLounge");

    // Start timeout in case video does not load
    setTimeout(
      function () {
        this.setState({ introVideoComplete: true });
      }.bind(this),
      6000
    );

    //alert("This area is for demonstration purposes only - please return to the lobby.")
  }

  render () {
    return (
      <div>
        <div className="imageNavDiv">
          <video
            className="imageNavBackground"
            autoPlay
            muted
            id="introVideo"
            onEnded={() => this.setState({ introVideoComplete: true })}
          >
            <source
              src={window.$videoPath + "demoVenues/warehouse/altNetworking4.webm"}
              type="video/webm"
            />
            <source
              src={window.$videoPath + "demoVenues/warehouse/altNetworking4.mp4"}
              type="video/mp4"
            />
          </video>

          <CSSTransition
            in={this.state.introVideoComplete}
            timeout={1500}
            classNames="imageNavBackground"
            unmountOnExit
          >
            <img
              className="imageNavBackground"
              src={window.$videoPath + "demoVenues/warehouse/altNetworking4.jpg"}
              alt="Background"
            ></img>
          </CSSTransition>

          {this.state.introVideoComplete ? (
            <div>
              {/**<img
                className={`${styles.hoverHotspot} ${styles.galleryHoverHotspot}`}
                src="/hoverShapes/hoverSquare.png"
                alt="To Gallery"
                onClick={() => alert("This is for demonstration purposes only, once you've had a look around return to the main lobby.")}
              ></img>
              <img
                className={`${styles.hoverHotspot} ${styles.roundtablesHoverHotspot}`}
                src="/hoverShapes/hoverSquare.png"
                alt="To Roundtables"
                onClick={() => alert("This is for demonstration purposes only, once you've had a look around return to the main lobby.")}
              ></img>*/}
              <Link to="/networking4Gallery">
                <img
                  className={`${styles.hoverHotspot} ${styles.galleryHoverHotspot}`}
                  src="/hoverShapes/hoverSquare.png"
                  alt="To Gallery"
                ></img>
              </Link>
              <Link to="/networking4Roundtables">
                <img
                  className={`${styles.hoverHotspot} ${styles.roundtablesHoverHotspot}`}
                  src="/hoverShapes/hoverSquare.png"
                  alt="To Roundtables"
                ></img>
              </Link>
              <Link to="/lobby4">
                <img
                  className={`${styles.hoverHotspot} ${styles.lobbyHoverHotspot}`}
                  src="/hoverShapes/hoverSquare.png"
                  alt="To Lobby"
                ></img>
              </Link>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
