import React, { Component } from "react";
import dompurify from "dompurify";
import Modal from "react-modal";
import { Image, Transformation } from "cloudinary-react";
import styles from "./GalleryModal.module.scss";

export default class PresentersGalleryModal extends Component {

  sanitizeCustomField = (customField) => {
    return { __html: dompurify.sanitize(customField) };
  };

  render() {
    return (
      <Modal
        isOpen={this.props.showPresenterModal}
        ariaHideApp={false}
        onRequestClose={this.handlePresenterModalClose}
        contentLabel="Presenter Modal"
        overlayClassName={styles.modalOverlay}
        className={styles.modalContent}
      >
        {this.props.selectedPresenter ? (
          <div className={styles.modalFlexboxColumn}>
            <div className={styles.modalTitleBar} style={{ backgroundImage: 'url(' + window.$videoPath + 'cornerNav/modalHeader.png)', }}>
              <h1 className={styles.modalPageTitle}>  {this.props.selectedPresenter &&
                this.props.selectedPresenter.firstName}  {this.props.selectedPresenter &&
                  this.props.selectedPresenter.surname}</h1>
              <button
                className={styles.modalClose}
                variant="success"
                onClick={this.props.handlePresenterModalClose}
                aria-label="Close"
              >
                Close
                <img
                  className={styles.modalCloseIcon}
                  src="/icons/close-no-circle.png"
                  alt="Close"
                />
              </button>
            </div>

            <div className={styles.scrollableArea}>
              <div className={styles.columnContainer}>
                <div className={styles.leftProfileColumn}>
                  <div className={styles.profilePictureAndTextFieldsDiv}>
                    {this.props.selectedPresenter &&
                      this.props.selectedPresenter.userProfilePictureID ? (
                      <div className={styles.profilePictureArea}>
                        <img
                          className={styles.profilePictureBackground}
                          src={window.$videoPath + "icons/profilePictureBackground.png"}
                          alt="Detail"
                        />
                        <Image
                          className={styles.profilePicture}
                          cloudName="oijqec973f1nf"
                          publicId={
                            this.props.selectedPresenter.userProfilePictureID + ".png"
                          }
                          width="240"
                          height="240"
                          secure={true}
                        >
                          <Transformation
                            height="240"
                            width="240"
                            gravity="face"
                            crop="fill"
                            radius="max"
                          />
                        </Image>
                      </div>
                    ) : null}

                    <div className={styles.textAnswerFieldsDiv}>
                      {this.props.selectedPresenter.jobTitle &&
                        this.props.selectedPresenter.jobTitle.length > 1 ? (
                        <span>
                          <p className={styles.profileParameterTitles}>Job Title:</p>
                          <p dangerouslySetInnerHTML={this.sanitizeCustomField(this.props.selectedPresenter.jobTitle)} className={styles.profileParameterAnswer}>
                          </p>
                        </span>
                      ) : null}

                      {this.props.selectedPresenter.company &&
                        this.props.selectedPresenter.company.length > 1 ? (
                        <span>
                          <p className={styles.profileParameterTitles}>
                            Organisation:
                          </p>
                          <p dangerouslySetInnerHTML={this.sanitizeCustomField(this.props.selectedPresenter.company)} className={styles.profileParameterAnswer}>
                          </p>
                        </span>
                      ) : null}
                    </div>
                  </div>

                  <br />





                </div>

                <div className={styles.rightProfileColumn}>
                  {this.props.selectedPresenter.bio &&
                    this.props.selectedPresenter.bio.length > 1 ? (
                    <span>
                      <p className={styles.aboutTitlesText}>
                        About {this.props.selectedPresenter.firstName + " " + this.props.selectedPresenter.surname}
                      </p>
                      <p dangerouslySetInnerHTML={this.sanitizeCustomField(this.props.selectedPresenter.bio)} className={styles.noIconProfileParameterAnswer}>
                      </p>

                    </span>
                  ) : null}

                  {this.props.selectedPresenter.website &&
                    this.props.selectedPresenter.website.length > 1 ? (
                    <span>
                      <p className={styles.profileParameterTitles}>Website:</p>
                      <p className={styles.profileParameterAnswer}>
                        <a
                          href={this.props.selectedPresenter.website}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Click here to visit website
                        </a>
                        <br />
                      </p>
                    </span>
                  ) : null}


                  {this.props.selectedPresenter.talkTitle &&
                    this.props.selectedPresenter.talkTitle.length > 1 ? (
                    <span>
                      <p className={styles.profileParameterTitles}>Talk Title:</p>
                      <p className={styles.profileParameterAnswer} dangerouslySetInnerHTML={this.sanitizeCustomField(this.props.selectedPresenter.talkTitle)}>
                      </p>
                    </span>
                  ) : null}

                  {this.props.selectedPresenter.talkAbstract &&
                    this.props.selectedPresenter.talkAbstract.length > 1 ? (
                    <span>
                      <p className={styles.profileParameterTitles}>
                        Talk Abstract:
                      </p>
                      <p dangerouslySetInnerHTML={this.sanitizeCustomField(this.props.selectedPresenter.talkAbstract)} className={styles.noIconProfileParameterAnswer}>
                      </p>
                    </span>
                  ) : null}
                </div>
              </div>
              <br />
              <br />
            </div>
          </div>
        ) : null}
      </Modal>
    );
  }
}
