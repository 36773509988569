import React, { Component } from "react";
import { Link } from "react-router-dom";
import ExhibitionMobile from "./ExhibitionMobile";
import { requestPoints } from "./../helpers/requestPoints.js";
import styles from "./Exhibition.module.scss";

export default class Exhibition extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount () {
    requestPoints("visitExhibition", "visitExhibition");
  }

  render () {
    return (
      <div>
        {this.props.showMobile ?
          <ExhibitionMobile
          />
          :
          <div className={styles.masterPageDiv}>
            <img
              className="imageNavBackground"
              src={window.$videoPath + "exhibition/fullScreenVisuals/" + this.props.language + "/exhibition.jpg"}
              alt="Background"
            ></img>


            <Link to="/lobby">
              <img
                className={`${styles.hoverHotspot} ${styles.lobbyHoverHotspot}`}
                src="/hoverShapes/hoverSquare.png"
                alt="Navigate to Lobby"
              ></img>
            </Link>

            <Link to="/exhibitionHall/1">
              <img
                className={`${styles.hallButtonOff} ${styles.hall1Hotspot}`}
                src={window.$videoPath + "exhibition/visualAssets/" + this.props.language + "/hall1Off.png"}
                alt="Navigate to Hall"
              ></img>
              <img
                className={`${styles.hallButtonOn} ${styles.hall1HotspotOn}`}
                src={window.$videoPath + "exhibition/visualAssets/" + this.props.language + "/hall1On.png"}
                alt="Navigate to Hall"
              ></img>
            </Link>

            <Link to="/exhibitionHall/1">
              <img
                className={`${styles.hallButtonOff} ${styles.hall2Hotspot}`}
                src={window.$videoPath + "exhibition/visualAssets/" + this.props.language + "/hall2Off.png"}
                alt="Navigate to Hall"
              ></img>
              <img
                className={`${styles.hallButtonOn} ${styles.hall2HotspotOn}`}
                src={window.$videoPath + "exhibition/visualAssets/" + this.props.language + "/hall2On.png"}
                alt="Navigate to Hall"
              ></img>
            </Link>

            <Link to="/exhibitionHall/1">
              <img
                className={`${styles.hallButtonOff} ${styles.hall3Hotspot}`}
                src={window.$videoPath + "exhibition/visualAssets/" + this.props.language + "/hall3Off.png"}
                alt="Navigate to Hall"
              ></img>
              <img
                className={`${styles.hallButtonOn} ${styles.hall3HotspotOn}`}
                src={window.$videoPath + "exhibition/visualAssets/" + this.props.language + "/hall3On.png"}
                alt="Navigate to Hall"
              ></img>
            </Link>

            <Link to="/exhibitionHall/1">
              <img
                className={`${styles.hallButtonOff} ${styles.hall4Hotspot}`}
                src={window.$videoPath + "exhibition/visualAssets/" + this.props.language + "/hall4Off.png"}
                alt="Navigate to Hall"
              ></img>
              <img
                className={`${styles.hallButtonOn} ${styles.hall4Hotspot}`}
                src={window.$videoPath + "exhibition/visualAssets/" + this.props.language + "/hall4On.png"}
                alt="Navigate to Hall"
              ></img>
            </Link>

            <Link to="/exhibitionHall/1">
              <img
                className={`${styles.hallButtonOff} ${styles.hall5Hotspot}`}
                src={window.$videoPath + "exhibition/visualAssets/" + this.props.language + "/hall5Off.png"}
                alt="Navigate to Hall"
              ></img>
              <img
                className={`${styles.hallButtonOn} ${styles.hall5Hotspot}`}
                src={window.$videoPath + "exhibition/visualAssets/" + this.props.language + "/hall5On.png"}
                alt="Navigate to Hall"
              ></img>
            </Link>

            <Link to="/exhibitionHall/1">
              <img
                className={`${styles.hallButtonOff} ${styles.hall6Hotspot}`}
                src={window.$videoPath + "exhibition/visualAssets/" + this.props.language + "/hall6Off.png"}
                alt="Navigate to Hall"
              ></img>
              <img
                className={`${styles.hallButtonOn} ${styles.hall6Hotspot}`}
                src={window.$videoPath + "exhibition/visualAssets/" + this.props.language + "/hall6On.png"}
                alt="Navigate to Hall"
              ></img>
            </Link>

            <Link to="/exhibitionStand/5ec97f345fa3230b905ddc06"></Link>
          </div>
        }
      </div>
    );
  }
}
