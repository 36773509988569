import React, { Component } from "react";
import ChatFunction from "./ChatFunction";

export default class CirclesOverlay extends Component {

  render () {
    return (
      <div className="imageNavDiv">

        <ChatFunction
          userProfile={this.props.userProfile}
          chatChannel="circlesTest1233545f"
        />
      </div>
    );
  }
}

