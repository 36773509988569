import React, { Component } from "react";
import { Redirect } from "react-router-dom";
//import PreLoginLanguageSelection from "./PreLoginLanguageSelection"
import Register from "./Register";
import RequestPasswordReset from "./RequestPasswordReset";
import LoginHelp from "./LoginHelp";
import styles from "./Login.module.scss";
import preLoginStyles from "./Pre-Login.module.scss";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      redirectToHome: false,
      redirectToHoldingPage: false,
      showRequestPasswordResetDiv: false,
      showRegisterDiv: false,
      showLoginHelpDiv: false,
    };
  }

  handleInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  onSubmit = (event) => {
    event.preventDefault();

    if (this.state.email.length < 1) {
      alert("Please enter your email address");
    } else if (this.state.password.length < 1) {
      alert("Please enter your password");
    } else {
      fetch("/api/authenticate", {
        method: "POST",
        body: JSON.stringify(this.state),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (res.status === 200) {
            // Note login priviledge
            res.json().then((data) => {
              const roleResponse = data.role;
              console.log("Login response: " + roleResponse);
              if (roleResponse.includes("awaitingConfirmation")) {
                alert(
                  "Please ensure you have clicked the link in your account confirmation email"
                );
              } else if (roleResponse.includes("awaitingApproval")) {
                alert(
                  "Your registration request is still pending approval.  For any enquiries please contact enquiries@liveunion.co.uk"
                );
              } else if (roleResponse.includes("standard")) {
                this.setState({ redirectToHome: true });
              } else if (roleResponse.includes("admin")) {
                this.setState({ redirectToHome: true });
              } else if (roleResponse.includes("holding")) {
                this.setState({ redirectToHoldingPage: true });
              }
              this.props.checkLoginStatus();
            });
          } else {
            const error = new Error(res.error);
            throw error;
          }
        })
        .catch((err) => {
          console.error(err);
          alert(
            "There was an error with your login. Please check your email and/or password are correct. If you have not set up an account yet please click below to set one up. "
          );
        });
    } // End of else for no form validation errors found
  };

  handleCloseRegisterDiv = () => {
    this.setState({
      showRegisterDiv: false,
    });
  };

  handleCloseLoginHelpDiv = () => {
    this.setState({
      showLoginHelpDiv: false,
    });
  };
  handleCloseRequestPasswordResetDiv = (event) => {
    this.setState({
      showRequestPasswordResetDiv: false,
    });
  };

  render() {
    if (this.state.redirectToHome === true) {
      return (
        <div>
          <Redirect to="/lobby" />
        </div>
      );
    } else if (this.state.redirectToHoldingPage === true) {
      return (
        <div>
          <Redirect to="/holding" />
        </div>
      );
    } else {
      return (
        <div className={styles.masterPageDiv}>
          <img
            className={styles.fullScreenBackground}
            src={window.$videoPath + "login/loginBackground.jpg"}
            alt="Background"
          ></img>

          {this.state.showRequestPasswordResetDiv ? (
            <RequestPasswordReset
              handleCloseRequestPasswordResetDiv={
                this.handleCloseRequestPasswordResetDiv
              }
              showHelp={() => this.setState({ showLoginHelpDiv: true })}
            />
          ) : null}

          {this.state.showRegisterDiv ? (
            <Register
              handleCloseRegisterDiv={this.handleCloseRegisterDiv}
              cookies={this.props.cookies}
              showHelp={() => this.setState({ showLoginHelpDiv: true })}
            />
          ) : null}

          {this.state.showLoginHelpDiv ? (
            <LoginHelp
              handleCloseLoginHelpDiv={this.handleCloseLoginHelpDiv}
            />
          ) : null}

          <div className={styles.loginModal} >
            <div className={styles.loginModalInnerDiv}>
              <img
                className={preLoginStyles.preLoginBoxLogo}
                src={window.$videoPath + "logo.png"}
                alt="Logo"
              />
              <img
                className={preLoginStyles.helpTab}
                src={window.$videoPath + "login/helpTab.png"}
                onClick={() => this.setState({ showLoginHelpDiv: true })}
                alt="Help"
              />
              <br />
              <h2 className={preLoginStyles.titleText}>WELCOME</h2>
              <br />
              <h2 className={styles.signInText}>Already registered? Sign in:</h2>
              <form onSubmit={this.onSubmit}>
                <input
                  className={preLoginStyles.textEntry}
                  type="email"
                  name="email"
                  size="50"
                  placeholder="Email address"
                  tabIndex="1"
                  value={this.state.email}
                  onChange={this.handleInputChange}
                  required
                />
                <input
                  className={preLoginStyles.textEntry}
                  type="password"
                  name="password"
                  placeholder="Password"
                  tabIndex="2"
                  value={this.state.password}
                  onChange={this.handleInputChange}
                  required
                />
                <div className={preLoginStyles.loginButtonsRow}>
                  <button
                    className={styles.loginButton}
                    onClick={() =>
                      this.setState({ showRequestPasswordResetDiv: true })
                    }
                    type="button"
                  >
                    Forgot Password?
                  </button>
                  <div style={{ flex: 1 }} />
                  <button
                    className={styles.loginButton}
                    onClick={() => this.setState({ showRegisterDiv: true })}
                    type="button"
                  >
                    New User? Register Here
                  </button>
                </div>
                <div className={preLoginStyles.loginButtonsRow}>
                  <div style={{ flex: 1 }} />
                  <button
                    className={styles.highlightedButton}
                    onClick={this.onSubmit}
                    tabIndex="3"
                    type="submit"
                  >
                    Login
                  </button>
                  <div style={{ flex: 1 }} />
                </div>
              </form>


              {/**<br />
              <br />
              <br />
              <br />
              <PreLoginLanguageSelection
                setLanguage={this.props.setLanguage}
                cookies={this.props.cookies}
                />*/}
              <img src={window.$videoPath + "login/poweredBy.png"} className={preLoginStyles.loginBoxFooter} alt="Login Footer" />

            </div>
          </div>
        </div>
      );
    }
  }
}
