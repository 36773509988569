import React, { Component } from "react";
import { Link } from "react-router-dom";
import { requestPoints } from "../helpers/requestPoints.js";
import styles from "./RoundtablesMobile.module.scss";

export default class PostersMobile extends Component {

  componentDidMount () {
    requestPoints("visitRoundtablesMobile", "visitRoundtablesMobile");
  }

  render () {
    const roundtablesList = this.props.roundtables.map(
      (roundtable) => {
        return (
          <Link
            to={"/roundtableSession/" + roundtable._id}
          >
            <div
              key={roundtable._id}
              className={styles.mobileListItem}
            >
              <h3 className={styles.mobileListTitle}>{roundtable.tableName}</h3>
            </div>
          </Link>
        );
      }
    );

    return (
      <div className={styles.mobileListView} >
        <div className={styles.mobileTitleArea}>
          <h1>ROUNDTABLES</h1>
        </div>
        {roundtablesList}
      </div>
    );
  }
}
