import React, { Component } from "react";
import styles from "./ExhibitionSubmitDetails.module.scss";

export default class ExhibitionSubmitDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
        };
    }

    componentDidMount = () => {

    };

    handleChange = (event) => {
        const { value, name } = event.target;
        this.setState({
            [name]: value,
        });
    };


    handleSubmit = (event) => {
        event.preventDefault();

        if (this.state.message.length < 1) {
            alert("Please include a message");
        } else {
            fetch("/api/sendExhibitorMessage", {
                method: "POST",
                body: JSON.stringify({ message: this.state.message, exhibitionStandID: this.props.exhibitionStandID }),
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        alert("Message successfully sent")
                        // Clear message box
                        this.setState({ message: "" })
                    } else {
                        const error = new Error(res.error);
                        throw error;
                    }
                })
                .catch((err) => {
                    console.error(err);
                    alert("Error sending message");
                });
        }
    };

    render () {
        return (
            <div>
                <div className={styles.formContainer}>
                    <form onSubmit={this.handleSubmit} noValidate>
                        <br />
                        <label htmlFor="message">Message:</label>
                        <textarea
                            type="text"
                            name="message"
                            className={styles.textEntry}
                            rows="10"
                            placeholder="Message"
                            value={this.state.message}
                            onChange={this.handleChange}
                            required
                        />
                        <br />
                        <br />

                        <input
                            type="submit"
                            value="Submit"
                            className="btn btn-success float-right submitButton"
                        />
                    </form>
                </div>
            </div>
        );
    }
}
