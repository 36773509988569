function getLocaleCode (userProfile) {
  switch (userProfile.userLanguage) {
    case "en":
      return ("en-GB");
    case "es":
      return ("es-ES");
    case "fr":
      return ("fr-FR")
    case "zh":
      return ("zh-CN")
    default:
      return ("en-GB");
  }
}

function lookupTimezoneAbbreviation (selectedTimestamp, userProfile) {
  // Can only get pretty 3 letter abbreviation for timezone names referred to in this locale
  // E.g. only US locale knows PST and only GB knows CEST etc.
  if (userProfile.userTimezone.offset < 0) {
    return (selectedTimestamp.toLocaleString("en-US", { timeZone: userProfile.userTimezone.value, timeZoneName: "short" }).split(' ')[3]);
  } else {
    return (selectedTimestamp.toLocaleString("en-GB", { timeZone: userProfile.userTimezone.value, timeZoneName: "short" }).split(' ')[2]);
  }
}

export const dateFormatterTimeOnly = (timestamp, userProfile) => {
  const selectedTimestamp = new Date(timestamp);
  return (selectedTimestamp.toLocaleString(getLocaleCode(userProfile), { timeZone: userProfile.userTimezone.value, timeStyle: "short" }) + " " + lookupTimezoneAbbreviation(selectedTimestamp, userProfile))
};

export const dateFormatterTimeOnly12Hour = (timestamp, userProfile) => {
  const selectedTimestamp = new Date(timestamp);
  return (selectedTimestamp.toLocaleString(getLocaleCode(userProfile), { timeZone: userProfile.userTimezone.value, timeStyle: "short", hour12: true }) + " " + lookupTimezoneAbbreviation(selectedTimestamp, userProfile))
};

export const dateFormatterTimeAndDay = (timestamp, userProfile) => {
  const selectedTimestamp = new Date(timestamp);
  return (selectedTimestamp.toLocaleString(getLocaleCode(userProfile), { timeZone: userProfile.userTimezone.value, weekday: "short" }) + " " + selectedTimestamp.toLocaleString('en-UK', { timeZone: userProfile.userTimezone.value, timeStyle: "short" }) + " " + lookupTimezoneAbbreviation(selectedTimestamp, userProfile))
};
