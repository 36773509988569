import React, { Component } from "react";
import Modal from "react-modal";
import Button from "react-bootstrap/Button";
import VideoPlayer from "./../modules/videoPlayer/VideoPlayer"
import { requestPoints } from "../helpers/requestPoints.js";
import ChatFunction from "./../twilioChat/ChatFunction";
import styles from "./Help.module.scss";

export default class Help extends Component {
  constructor() {
    super();
    this.state = {
      showChat: false,
      showFAQ: false,
      showWelcomeVideo: true,
    };
  }

  helpModalOnAfterOpen = () => {
    requestPoints("other", "viewHelp");
  }

  showChat = () => {
    this.setState({
      showChat: true,
      showFAQ: false,
      showWelcomeVideo: false,
    });
  };

  showFAQ = () => {
    this.setState({
      showChat: false,
      showFAQ: true,
      showWelcomeVideo: false,
    });
  };

  showWelcomeVideo = () => {
    this.setState({
      showChat: false,
      showFAQ: false,
      showWelcomeVideo: true,
    });
  };

  render () {
    return (
      <div>
        <Modal
          onAfterOpen={this.helpModalOnAfterOpen}
          ariaHideApp={false}
          isOpen={this.props.showHelpModal}
          onRequestClose={() => this.props.setShowHelpModal(false)}
          contentLabel="Help Modal"
          overlayClassName={styles.modalOverlay}
          className={styles.modalContent}
          style={{ content: { backgroundImage: "url(" + window.$videoPath + "cornerNav/modalBackground.png)" } }}
        >
          <div className={styles.modalFlexboxColumn}>
            <div className={styles.modalTitleBar} style={{ backgroundImage: 'url(' + window.$videoPath + 'cornerNav/modalHeader.png)', }}>
              <h1 className={styles.modalPageTitle}>Help</h1>
              <button
                className={styles.modalClose}
                variant="success"
                onClick={() => this.props.setShowHelpModal(false)}
              >
                Close
                <img
                  className={styles.modalCloseIcon}
                  src="/icons/close-no-circle.png"
                  alt="Close"
                />
              </button>
            </div>

            <div className={styles.sectionSelectionPanel}>
              {this.state.showChat ? (
                <Button
                  variant="success"
                  className={styles.sectionSelectionButton}
                  onClick={this.showChat}
                >
                  Ask a question
                </Button>
              ) : (
                <Button
                  variant="primary"
                  className={styles.sectionSelectionButton}
                  onClick={this.showChat}
                >
                  Ask a question
                </Button>
              )}
              {this.state.showFAQ ? (
                <Button
                  variant="success"
                  className={styles.sectionSelectionButton}
                  onClick={this.showFAQ}
                >
                  FAQ
                </Button>
              ) : (
                <Button
                  variant="primary"
                  className={styles.sectionSelectionButton}
                  onClick={this.showFAQ}
                >
                  FAQ
                </Button>
              )}
              {this.state.showWelcomeVideo ? (
                <Button
                  variant="success"
                  className={styles.sectionSelectionButton}
                  onClick={this.showWelcomeVideo}
                >
                  Welcome video
                </Button>
              ) : (
                <Button
                  variant="primary"
                  className={styles.sectionSelectionButton}
                  onClick={this.showWelcomeVideo}
                >
                  Welcome video
                </Button>
              )}
            </div>

            <div className={styles.scrollableArea}>
              {this.state.showChat ? (
                <div>
                  <h1>Request help</h1>
                  <p className={styles.helpMessage}>
                    If you require any help during the event, please submit your
                    request below. A member of our support team will be with you
                    as soon as possible!
                  </p>
                  <br />
                  <div className={styles.helpChatBoxArea}>
                    <ChatFunction
                      userProfile={this.props.userProfile}
                      chatChannel="help"
                    />
                  </div>
                  <br />
                </div>
              ) : null}
              {this.state.showFAQ ? (
                <div>
                  <h1>FAQ</h1>
                  <div className={styles.faqElements}>
                    <p>
                      <br />
                      <strong>I am having technical issues. Who can I contact?</strong>
                      <br />
                      Click on <strong>Help</strong>, where our support team is available from 09:00 - 20:00 on Wednesday 30th June 2021. For any queries outside of this time, please contact <a href="mailto:info@beehive.global">info@beehive.global</a>
                    </p>
                    <p>
                      <strong>Where can I find out what is happening during the event?</strong>
                      <br />
                      Click on the <strong>Agenda</strong> in the top right corner to see what content and sessions are available.
                    </p>
                    <p>
                      <strong>Where do I go to watch the Keynote session?</strong>
                      <br />
                      This will take place in the plenary - accessible via the lobby or the navigation bar. Check the Agenda for times.
                    </p>
                    <p>
                      <strong>Where do I go to book a 1:1 Meeting?</strong>
                      <br />
                      Click on the Attendee tab in the top bar to see LU Team members who are available to meet with.
                    </p>
                    <p>
                      <strong>Where can I discover On-Demand Content?</strong>
                      <br />
                      On-demand content is located in the On-Demand tab and in the following breakout rooms – ‘What’s Next For Events? and ‘Breakouts Explained’.
                    </p>
                    <p>
                      <strong>Where can I find the Live Union case studies?</strong>
                      <br />
                      These are located in the poster gallery - click on each poster pod to view our mini case studies.
                    </p>
                    <p>
                      <strong>How can I explore your other virtual environments?</strong>
                      <br />
                      Click on Warehouse Tab via the navigation bar to discover the Warehouse Environment.
                    </p>
                    <p>
                      <strong>I’d like to get in touch with someone after the event, who can I contact?</strong>
                      <br />
                      Email <a href="mailto:enquiries@liveunion.co.uk">enquiries@liveunion.co.uk</a> with all your Beehive or Live Union related enquiries.
                    </p>
                  </div>

                  <br />
                </div>
              ) : null}
              {this.state.showWelcomeVideo ? (
                <VideoPlayer
                  resourceLocator={"vimeo://541520382"}
                  location={"help-welcome-video"}
                  userProfile={this.props.userProfile}
                  autoplay={true}
                />
              ) : null}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
