import React, { Component } from "react";
import dompurify from "dompurify";
import { Link } from "react-router-dom";
import Player from "@vimeo/player";
import { CSSTransition } from "react-transition-group";
import { requestPoints } from "../helpers/requestPoints.js";
import styles from "./Breakout.module.scss";

// Define once to avoid multiple on plays being registered
var iframe;
var player = null;

export default class Breakout extends Component {
  constructor() {
    super();
    //Set default message
    this.state = {
      breakoutName: "",
      breakoutLiveURL: "",
      breakoutPrerecordURL: "",
      showMoreInfoIframe: false,
      showQuestionsModal: false,
      isSmallBreakout: true,
      videoStateForwards: true,
      showContent: false,
      breakoutLiveControlChannel: 1,
      slidoEventID: "",
    };
    this.breakoutVideo = React.createRef();
    this.reverseBreakoutVideo = React.createRef();
  }

  componentDidMount () {
    // If this is a small breakout we need to change the opening frame on load
    // In this case all breakouts are small breakouts
    /**if (
      this.props.match.params.breakoutID.includes("5f240c59a23ba10b65f7f67d")
    ) {
      this.setState({ isSmallBreakout: true });
    } */

    fetch(
      "/api/getBreakout?breakoutID=" +
      // Get selected stand ID from React Router path
      this.props.match.params.breakoutID
    )
      // Need to somehow request by received prop ID
      .then((res) => res.json())
      .then((data) => this.loadResponseIntoState(data));

    // Move straight to presentation state
    this.viewPresentation();
  }

  componentDidUpdate () { }

  componentWillUnmount () {
    // Restore corner menu
    this.props.setCornerMenuStatus(1);
  }

  loadResponseIntoState (data) {
    console.log(data);
    this.setState({
      //breakoutName: data.breakout.breakoutName,
      breakoutLiveURL: data.breakout.breakoutLiveURL,
      breakoutPrerecordURL: data.breakout.breakoutPrerecordURL,
      breakoutLiveControlChannel: data.breakout.breakoutLiveControlChannel,
      slidoEventID: data.breakout.slidoEventID,
    });
  }

  sanitizeCustomField = (customField) => {
    return { __html: dompurify.sanitize(customField) };
  };

  viewPresentation = () => {
    if (this.breakoutVideo) {
      this.breakoutVideo.play();
    }
    // Set timeout in case video does not load/finish
    setTimeout(
      function () {
        this.forwardsVideoEnded();
      }.bind(this),
      3000
    );
  };

  leavePresentation = () => {
    // Invalidate reference to Vimeo player to ensure we re-register when we zoom back in
    player = null;
    this.setState({ showContent: false });
    if (this.reverseBreakoutVideo) {
      this.reverseBreakoutVideo.play();
    }
    // Set timeout in case video does not load/finish
    setTimeout(
      function () {
        this.reverseVideoEnded();
      }.bind(this),
      3000
    );
  };

  forwardsVideoEnded = () => {
    this.setState({ videoStateForwards: false, showContent: true });
    // Hide corner menu
    this.props.setCornerMenuStatus(3);
    // The iFrame is not a vimeo player in here
    //this.registerVimeoPlayListener();
  };

  reverseVideoEnded = () => {
    // Need to reset breakout video back to start before transitioning to it
    if (this.breakoutVideo) {
      this.breakoutVideo.currentTime = 0;
    }
    this.setState({ videoStateForwards: true, showContent: false });
  };

  render () {
    // Select appropriate remote control channel to listen to
    var breakoutLive = 0;

    return (
      <div>
        <div className="imageNavDiv">
          {this.state.videoStateForwards ? (
            <div>
              <div
                className={styles.goToPresentation}
                onClick={this.viewPresentation}
              ></div>
              <Link to="/breakoutsStill">
                <img
                  className={`${styles.hoverHotspot} ${styles.exitHoverHotspot}`}
                  src="/hoverShapes/hoverSquare.png"
                  alt="Exit"
                ></img>
              </Link>
            </div>
          ) : null}
          {!this.state.videoStateForwards ? (
            <div
              className={styles.leavePresentation}
              onClick={this.leavePresentation}
            ></div>
          ) : null}

          <div>
            <video
              className="imageNavBackground"
              id="myVideo"
              loop={false}
              muted
              ref={(breakoutVideo) => (this.breakoutVideo = breakoutVideo)}
              onEnded={() => this.forwardsVideoEnded()}
            >
              <source
                src={window.$videoPath + "visuals/" + this.props.language + "/largeSeatedBreakout.mp4"}
                type="video/mp4"
              />
              <source
                src={window.$videoPath + "visuals/" + this.props.language + "/largeSeatedBreakout.webm"}
                type="video/webm"
              />
            </video>
            <CSSTransition
              in={!this.state.videoStateForwards}
              timeout={1500}
              classNames="imageNavBackground"
              unmountOnExit
            >
              <video
                className="imageNavBackground"
                id="myVideo"
                loop={false}
                muted
                ref={(reverseBreakoutVideo) =>
                  (this.reverseBreakoutVideo = reverseBreakoutVideo)
                }
                onEnded={() => this.reverseVideoEnded()}
              >
                <source
                  src={window.$videoPath + "visuals/" + this.props.language + "/largeSeatedBreakoutReverse.mp4"}
                  type="video/mp4"
                />
                <source
                  src={window.$videoPath + "visuals/" + this.props.language + "/largeSeatedBreakoutReverse.webm"}
                  type="video/webm"
                />
              </video>
            </CSSTransition>
          </div>

          <CSSTransition
            in={this.state.showContent}
            timeout={1500}
            classNames="imageNavBackground"
            unmountOnExit
          >
            <div className={styles.largeBreakoutiFrameDiv}>
              {this.props.match.params.breakoutID == "61409f528999576d94879702" ?
                <iframe
                  id="contentIframe"
                  title="Content iFrame"
                  className={styles.iFrameClass}
                  src="https://spatial.chat/s/beehivedemo"
                  frameborder="0"
                  seamless="seamless"
                  height="700px"
                  width="100%"
                  scrolling="yes"
                  allow="camera *;microphone *;autoplay">
                </iframe> : null}
              {this.props.match.params.breakoutID == "614ae6f92dd4d144fda9457e" ?
                <iframe
                  id="contentIframe"
                  title="Content iFrame"
                  className={styles.iFrameClass}
                  src="https://engamio.live/live?code=928995"
                  frameborder="1"
                  height="720px"
                  width="100%"
                  scrolling="no"
                  allow="camera *;microphone *;autoplay">
                  allowfullscreen
                </iframe> : null}
              {this.props.match.params.breakoutID == "6086abb259b00d4eefcc69b0" ?
                <iframe
                  id="contentIframe"
                  title="Content iFrame"
                  className={styles.iFrameClass}
                  src="https://liveunionembedded.whereby.com/demo20293c91134d-24e0-4c43-8f4c-6f70b3ed75ce"
                  frameborder="1"
                  height="720px"
                  width="100%"
                  scrolling="no"
                  allow="camera *;microphone *;autoplay">
                  allowfullscreen
                </iframe> : null}
            </div>
          </CSSTransition>

        </div>
      </div>
    );
  }
}
