import React, { Component } from "react";
import { CSSTransition } from "react-transition-group";
import { Redirect } from "react-router-dom";
import { requestPoints } from "../../../helpers/requestPoints";

export default class Networking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      introVideoComplete: false,
    };
  }

  componentDidMount () {
    requestPoints("visitNetworkingLounge", "visitNetworkingLounge");

    // Start timeout in case video does not load
    setTimeout(
      function () {
        this.setState({ introVideoComplete: true });
      }.bind(this),
      6000
    );
  }

  render () {
    return (
      <div>
        <div className="imageNavDiv">
          <video
            className="imageNavBackground"
            autoPlay
            muted
            id="introVideo"
            onEnded={() => this.setState({ introVideoComplete: true })}
          >
            <source
              src={window.$videoPath + "demoVenues/warehouse/altNetworking4Roundtables.webm"}
              type="video/webm"
            />
            <source
              src={window.$videoPath + "demoVenues/warehouse/altNetworking4Roundtables.mp4"}
              type="video/mp4"
            />
          </video>

          <CSSTransition
            in={this.state.introVideoComplete}
            timeout={1500}
            classNames="imageNavBackground"
            unmountOnExit
          >
            <img
              className="imageNavBackground"
              src={window.$videoPath + "demoVenues/warehouse/altNetworking4Roundtables.jpg"}
              alt="Background"
            ></img>
          </CSSTransition>

          {this.state.introVideoComplete ? (
            <div>
              <Redirect to="/visualRoundtables" />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
