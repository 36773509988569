import React, { Component } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import Firebase from "../helpers/firebase.js";
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import styles from "./RemoteControl.module.scss";

const db = Firebase.firestore();
const auth = firebase.auth();

export default class RemoteControl extends Component {
  constructor() {
    super();
    this.state = {
      navigation: "",
      popUp: false,
      navBarStatus: 1,
      plenaryOpenStatus: "open",
      plenaryContent: "blank",
      plenaryPosition: 1,
      notificationText: "",
      circlesLive: "closed",
      breakout1Live: 0,
      breakout2Live: 0,
      breakout3Live: 0,
      breakout4Live: 0,
      breakout5Live: 0,
      breakout6Live: 0,
      breakout7Live: 0,
      breakout8Live: 0,
      breakout9Live: 0,
      breakout10Live: 0,
      lobbyScreen: "",
      zoomMeetingID: "",
      zoomPassword: "",
      webinarURL: "",
    };
    this.db = Firebase.firestore();
  }

  componentDidMount () {
    this.retrieveRemoteControlSettings();

    auth.signOut();
    this.retrieveRemoteControlWriteCredentials();
  }

  retrieveRemoteControlSettings () {
    fetch("/api/getRemoteControlSettings")
      .then((res) => res.json())
      .then((data) => this.loadResponseIntoState(data));
  }

  loadResponseIntoState (data) {
    console.log("Update: " + data.remoteControlSettings.navigation);
    this.setState({
      navigation: data.remoteControlSettings.navigation,
      popUp: data.remoteControlSettings.popUp,
      navBarStatus: data.remoteControlSettings.navBarStatus,
      plenaryOpenStatus: data.remoteControlSettings.plenaryOpenStatus,
      plenaryContent: data.remoteControlSettings.plenaryContent,
      plenaryPosition: data.remoteControlSettings.plenaryPosition,
      notificationText: data.remoteControlSettings.notificationText,
      circlesLive: data.remoteControlSettings.circlesLive,
      breakout1Live: data.remoteControlSettings.breakout1Live,
      breakout2Live: data.remoteControlSettings.breakout2Live,
      breakout3Live: data.remoteControlSettings.breakout3Live,
      breakout4Live: data.remoteControlSettings.breakout4Live,
      breakout5Live: data.remoteControlSettings.breakout5Live,
      breakout6Live: data.remoteControlSettings.breakout6Live,
      breakout7Live: data.remoteControlSettings.breakout7Live,
      breakout8Live: data.remoteControlSettings.breakout8Live,
      breakout9Live: data.remoteControlSettings.breakout9Live,
      breakout10Live: data.remoteControlSettings.breakout10Live,
      lobbyScreen: data.remoteControlSettings.lobbyScreen,
      zoomMeetingID: data.remoteControlSettings.zoomMeetingID,
      zoomPassword: data.remoteControlSettings.zoomPassword,
      webinarURL: data.remoteControlSettings.webinarURL,
    });
  }

  retrieveRemoteControlWriteCredentials = () => {
    fetch("/api/getRemoteControlWriteCredentials")
      .then((res) => res.json())
      .then((data) => this.authenticateRemoteControlWriteAccess(data));
  };

  authenticateRemoteControlWriteAccess = (data) => {
    console.log("Retrieved Firestore credentials");
    auth
      .signInWithEmailAndPassword(data.username, data.password)
      .catch((error) => {
        console.error("Error signing into remote control write access", error);
      });
  };

  updateInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleNavigationChange = (value) => {
    this.setState({
      navigation: value,
    });
  };

  handlePlenaryOpenStatusChange = (value) => {
    this.setState({
      plenaryOpenStatus: value,
    });
  };

  handleNavBarStatusChange = (value) => {
    this.setState({
      navBarStatus: value,
    });
  };

  handlePlenaryPositionChange = (value) => {
    this.setState({
      plenaryPosition: value,
    });
  };

  handlePlenaryContentChange = (value) => {
    this.setState({
      plenaryContent: value,
    });
  };

  handleNotificationTextChange = (value) => {
    this.setState({
      notificationText: value,
    });
  };

  handleCirclesLiveChange = (value) => {
    this.setState({
      circlesLive: value,
    });
  };

  handleBreakout1LiveChange = (value) => {
    this.setState({
      breakout1Live: value,
    });
  };

  handleBreakout2LiveChange = (value) => {
    this.setState({
      breakout2Live: value,
    });
  };

  handleBreakout3LiveChange = (value) => {
    this.setState({
      breakout3Live: value,
    });
  };

  handleBreakout4LiveChange = (value) => {
    this.setState({
      breakout4Live: value,
    });
  };

  handleBreakout5LiveChange = (value) => {
    this.setState({
      breakout5Live: value,
    });
  };

  handleBreakout6LiveChange = (value) => {
    this.setState({
      breakout6Live: value,
    });
  };

  handleBreakout7LiveChange = (value) => {
    this.setState({
      breakout7Live: value,
    });
  };

  handleBreakout8LiveChange = (value) => {
    this.setState({
      breakout8Live: value,
    });
  };

  handleBreakout9LiveChange = (value) => {
    this.setState({
      breakout9Live: value,
    });
  };

  handleBreakout10LiveChange = (value) => {
    this.setState({
      breakout10Live: value,
    });
  };

  handleLobbyScreenChange = (value) => {
    this.setState({
      lobbyScreen: value,
    });
  };

  saveSettings = () => {
    fetch("/api/updateRemoteControlSettings", {
      method: "POST",
      body: JSON.stringify(this.state),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 200) {
          console.log("Saved successfully");
        } else {
          const error = new Error(res.error);
          throw error;
        }
      })
      .catch((err) => {
        console.error(err);
        alert("Error updating settings -  please try again");
      });
  };

  sendToSite = (event) => {
    // Prevent page reload
    console.log("Submit remote control: " + this.state.notificationText);
    event.preventDefault();
    //db.collection("arm-research-summit")
    //.add({
    db.collection("beehive-osprey")
      .doc("QRSypBkVnYLxRnaeWmUS")
      .set({
        navigation: this.state.navigation,
        popUp: this.state.popUp,
        navBarStatus: this.state.navBarStatus,
        plenaryOpenStatus: this.state.plenaryOpenStatus,
        plenaryContent: this.state.plenaryContent,
        plenaryPosition: this.state.plenaryPosition,
        notificationText: this.state.notificationText,
        circlesLive: this.state.circlesLive,
        breakout1Live: this.state.breakout1Live,
        breakout2Live: this.state.breakout2Live,
        breakout3Live: this.state.breakout3Live,
        breakout4Live: this.state.breakout4Live,
        breakout5Live: this.state.breakout5Live,
        breakout6Live: this.state.breakout6Live,
        breakout7Live: this.state.breakout7Live,
        breakout8Live: this.state.breakout8Live,
        breakout9Live: this.state.breakout9Live,
        breakout10Live: this.state.breakout10Live,
        lobbyScreen: this.state.lobbyScreen,
        zoomMeetingID: this.state.zoomMeetingID,
        zoomPassword: this.state.zoomPassword,
        webinarURL: this.state.webinarURL,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(function (docRef) {
        console.log("Document written with ID");
      })
      .catch(function (error) {
        console.error("Error adding document: ", error);
      });

    this.saveSettings();
  };

  render () {
    return (
      <div className={styles.background}>
        <div className={styles.remoteControlArea}>
          <h1>Remote Control</h1>
          <form onSubmit={this.sendToSite}>
            <br />
            <h3>Force Users to Space:</h3>
            <ToggleButtonGroup
              type="radio"
              value={this.state.navigation}
              name="navigation"
              onChange={this.handleNavigationChange}
            >
              <ToggleButton className={styles.toggleButton} value={""}>
                Free Choice
              </ToggleButton>
              <ToggleButton value={"lobby"}>Lobby</ToggleButton>
              <ToggleButton value={"plenary"}>Plenary</ToggleButton>
              <ToggleButton value={"posters"}>Poster Gallery</ToggleButton>
            </ToggleButtonGroup>
            <br /> <br />
            <h3>Plenary Open</h3>
            <ToggleButtonGroup
              type="radio"
              value={this.state.plenaryOpenStatus}
              name="plenaryOpenStatus"
              onChange={this.handlePlenaryOpenStatusChange}
            >
              <ToggleButton value={"open"}>Open</ToggleButton>
              <ToggleButton value={"closed"}>Closed</ToggleButton>
            </ToggleButtonGroup>
            <br /> <br />
            <h3>Nav Bar Status</h3>
            <ToggleButtonGroup
              type="radio"
              value={this.state.navBarStatus}
              name="navBarStatus"
              onChange={this.handleNavBarStatusChange}
            >
              <ToggleButton value={0}>Hidden</ToggleButton>
              <ToggleButton value={1}>Visible</ToggleButton>
              <ToggleButton value={2}>Disabled (except Help)</ToggleButton>
            </ToggleButtonGroup>
            <br /> <br />
            <h3>Plenary Content Position (not in use)</h3>
            <ToggleButtonGroup
              type="radio"
              value={this.state.plenaryPosition}
              name="plenaryPosition"
              onChange={this.handlePlenaryPositionChange}
            >
              <ToggleButton value={0}>Hide Content</ToggleButton>
              <ToggleButton value={1}>Small Content Window</ToggleButton>
              <ToggleButton value={2}>Content Full Screen</ToggleButton>
            </ToggleButtonGroup>
            <br /> <br />
            <h3>Circles Live</h3>
            <ToggleButtonGroup
              type="radio"
              value={this.state.circlesLive}
              name="circlesLive"
              onChange={this.handleCirclesLiveChange}
            >
              <ToggleButton value={"closed"}>Circles Closed</ToggleButton>
              <ToggleButton value={"live"}>Circles Live</ToggleButton>
            </ToggleButtonGroup>
            <br /> <br />
            <h3>Plenary Content Source</h3>
            <ToggleButtonGroup
              type="radio"
              value={this.state.plenaryContent}
              name="plenaryContent"
              onChange={this.handlePlenaryContentChange}
            >
              <ToggleButton value={""}>Blank</ToggleButton>
              <ToggleButton value={"vimeo://432817748"}>
                Creating agendas for virtual events
              </ToggleButton>
              <ToggleButton value={"vimeo://457659438"}>
                Plenary Video
              </ToggleButton>
              <ToggleButton value={"vimeo://542124982"}>
                Open House Conor Plenary
              </ToggleButton>
              <ToggleButton value={"mux://lmMjDYHVxYs6gQRhOdNc00ivAcYMYeFw5e025hlZEFudQ"}>
                Mux Demo
              </ToggleButton>


            </ToggleButtonGroup>
            <br />
            <input
              type="text"
              name="plenaryContent"
              size={150}
              placeholder="Plenary Content Source"
              value={this.state.plenaryContent}
              onChange={this.updateInput}
            />
            <br /> <br />
            <h3>Breakout 1 Content Source - Marketplace</h3>
            <ToggleButtonGroup
              type="radio"
              value={this.state.breakout1Live}
              name="breakout1Live"
              onChange={this.handleBreakout1LiveChange}
            >
              <ToggleButton value={2}>Breakout 1 Opens At</ToggleButton>
              <ToggleButton value={0}>Breakout 1 Prerecord</ToggleButton>
              <ToggleButton value={1}>Breakout 1 Live</ToggleButton>
            </ToggleButtonGroup>
            <br /> <br />
            <h3>Breakout 2 Content Source - Ask Anything</h3>
            <ToggleButtonGroup
              type="radio"
              value={this.state.breakout2Live}
              name="breakout2Live"
              onChange={this.handleBreakout2LiveChange}
            >
              <ToggleButton value={2}>Breakout 2 Opens At</ToggleButton>
              <ToggleButton value={0}>Breakout 2 Prerecord</ToggleButton>
              <ToggleButton value={1}>Breakout 2 Live (Butter)</ToggleButton>
            </ToggleButtonGroup>
            <br /> <br />
            <h3>Breakout 3 Content Source - Whats Next</h3>
            <ToggleButtonGroup
              type="radio"
              value={this.state.breakout3Live}
              name="breakout3Live"
              onChange={this.handleBreakout3LiveChange}
            >
              <ToggleButton value={2}>Breakout 3 Opens At</ToggleButton>
              <ToggleButton value={0}>Breakout 3 Prerecord</ToggleButton>
              <ToggleButton value={1}>Breakout 3 Live</ToggleButton>
              <ToggleButton value={"vimeo://574675111"}>Vimeo Test</ToggleButton>
            </ToggleButtonGroup>
            <br /> <br />
            <h3>Breakout 4 Content Source - Breakouts Explained</h3>
            <ToggleButtonGroup
              type="radio"
              value={this.state.breakout4Live}
              name="breakout4Live"
              onChange={this.handleBreakout4LiveChange}
            >
              <ToggleButton value={2}>Breakout 4 Opens At</ToggleButton>
              <ToggleButton value={0}>Breakout 4 Prerecord</ToggleButton>
              <ToggleButton value={1}>Breakout 4 Live</ToggleButton>
            </ToggleButtonGroup>
            <br /> <br />
            <h3>Lobby Screen</h3>
            <ToggleButtonGroup
              type="radio"
              value={this.state.lobbyScreen}
              name="lobbyScreen"
              onChange={this.handleLobbyScreenChange}
            >
              <ToggleButton value={""}>Blank</ToggleButton>
              <ToggleButton value={"lobbyBackScreen"}>Demo Video</ToggleButton>
            </ToggleButtonGroup>
            <br /> <br />
            <h3>Zoom Meeting ID</h3>
            <input
              type="text"
              name="zoomMeetingID"
              size={100}
              placeholder="Zoom Meeting ID"
              value={this.state.zoomMeetingID}
              onChange={this.updateInput}
            />
            <br /> <br />
            <h3>Zoom Meeting Password</h3>
            <input
              type="text"
              name="zoomPassword"
              size={100}
              placeholder="Zoom Password"
              value={this.state.zoomPassword}
              onChange={this.updateInput}
            />
            <br /> <br />
            <h3>Webinar URL</h3>
            <input
              type="text"
              name="webinarURL"
              size={100}
              placeholder="Webinar URL"
              value={this.state.webinarURL}
              onChange={this.updateInput}
            />



            <br /> <br />
            <h3>Notifications</h3>
            <ToggleButtonGroup
              type="radio"
              value={this.state.notificationText}
              name="notificationText"
              onChange={this.handleNotificationTextChange}
            >
              <ToggleButton value={""}>Clear</ToggleButton>
              <ToggleButton
                value={
                  "Be sure to checkout who else is attending and book meet-ups in the Networking Lounge"
                }
              >
                Be sure to checkout who else is attending and book meet-ups in
                the Networking Lounge
              </ToggleButton>
              <ToggleButton
                value={
                  "Visit the networking lounge to connect with other delegates or participate in roundtables"
                }
              >
                Visit the networking lounge to connect with other delegates or
                participate in roundtables (with audio)
              </ToggleButton>
            </ToggleButtonGroup>
            <br />
            <input
              type="text"
              name="notificationText"
              size={100}
              placeholder="Notification text"
              value={this.state.notificationText}
              onChange={this.updateInput}
            />
            <br /> <br />
            {<button type="submit">Submit</button>}
          </form>
          <br />
          <br />
        </div>
      </div>
    );
  }
}
