import React, { Component } from "react";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { requestPoints } from "../../../helpers/requestPoints";
import styles from "./Plenary4.module.scss";

export default class Plenary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoStateForwards: true,
      previousPlenaryOpenStatus: "closed",
      showContent: false,
      showSimTrans: false,
    };
    this.plenaryVideo = React.createRef();
    this.reversePlenaryVideo = React.createRef();
  }

  // Check open status on entry to area
  componentDidMount () {
    requestPoints("visitPlenary", "visitPlenary");

    if (this.props.plenaryOpenStatus === "open") {
      this.viewPresentation();
    } else {
      alert(
        "The plenary is currently closed please check the agenda to see when the next session will be starting"
      );
    }
  }

  componentWillUnmount () {
    // Set corner menu to displayed again
    this.props.setCornerMenuStatus(1);
  }

  // Action zoom or unzoom if already in plenary and status changes
  componentDidUpdate () {
    if (
      this.props.plenaryOpenStatus === "open" &&
      this.props.plenaryOpenStatus !== this.state.previousPlenaryOpenStatus
    ) {
      this.setState({
        previousPlenaryOpenStatus: this.props.plenaryOpenStatus,
      });
      this.viewPresentation();
    }
    if (
      this.props.plenaryOpenStatus === "closed" &&
      this.props.plenaryOpenStatus !== this.state.previousPlenaryOpenStatus
    ) {
      this.setState({
        previousPlenaryOpenStatus: this.props.plenaryOpenStatus,
      });
      this.leavePresentation();
    }
  }

  viewPresentation = () => {
    if (this.plenaryVideo) {
      this.plenaryVideo.play();
    }
    // Set corner menu to hidden
    this.props.setCornerMenuStatus(3);
    // Start timeout in case video does not load
    setTimeout(
      function () {
        this.forwardsVideoEnded();
      }.bind(this),
      6000
    );
  };

  leavePresentation = () => {
    this.setState({
      showContent: false,
    });
    if (this.reversePlenaryVideo.play) {
      this.reversePlenaryVideo.play();
    }
    // Set timeout in case video does not load/finish
    setTimeout(
      function () {
        this.reverseVideoEnded();
      }.bind(this),
      6000
    );
  };

  forwardsVideoEnded = () => {
    this.setState({ videoStateForwards: false, showContent: true });
    // Repeat call to hide corner menu in case we transitioned from breakout and it tried to show it again on unmount!
    this.props.setCornerMenuStatus(3);
  };

  reverseVideoEnded = () => {
    if (this.plenaryVideo) {
      this.plenaryVideo.currentTime = 0;
    }
    this.setState({ videoStateForwards: true });
  };

  render () {
    // Set position preset of content window
    /**const iFrameDiv =
      this.props.plenaryPosition === 1
        ? styles.iFramePosition1
        : this.props.plenaryPosition === 2
        ? styles.iFramePosition2
        : styles.iFramePosition0; */
    //
    return (
      <div className="imageNavDiv">
        <video
          className="imageNavBackground"
          id="introVideo"
          loop={false}
          muted
          ref={(plenaryVideo) => (this.plenaryVideo = plenaryVideo)}
          onEnded={() => this.forwardsVideoEnded()}
        >
          <source src={window.$videoPath + "demoVenues/warehouse/altPlenary4.mp4"} type="video/mp4" />
          <source src={window.$videoPath + "demoVenues/warehouse/altPlenary4.webm"} type="video/webm" />
        </video>
        <CSSTransition
          in={!this.state.videoStateForwards}
          timeout={1500}
          classNames="imageNavBackground"
          unmountOnExit
        >
          <video
            className="imageNavBackground"
            id="introVideoReverse"
            muted
            loop={false}
            ref={(reversePlenaryVideo) =>
              (this.reversePlenaryVideo = reversePlenaryVideo)
            }
            onEnded={() => this.reverseVideoEnded()}
          >
            <source
              src={window.$videoPath + "demoVenues/warehouse/altPlenary4Reverse.mp4"}
              type="video/mp4"
            />
            <source
              src={window.$videoPath + "demoVenues/warehouse/altPlenary4Reverse.webm"}
              type="video/webm"
            />
          </video>
        </CSSTransition>

        {
          // Change button areas based on zoomed in or out state
          this.state.videoStateForwards ? (
            <div>
              <div
                className={styles.goToPresentation}
                onClick={this.viewPresentation}
              ></div>
              <Link to="/lobby4">
                <img
                  className={`${styles.hoverHotspot} ${styles.lobbyHoverHotspot}`}
                  src="/hoverShapes/hoverSquare.png"
                  alt="To lobby"
                ></img>
              </Link>
            </div>
          ) : (
            <div
              className={styles.leavePresentation}
              onClick={this.leavePresentation}
            ></div>
          )
          // End of button areas
        }

        <CSSTransition
          in={
            this.props.plenaryContent != null &&
            this.props.plenaryContent.length > 5 &&
            !this.state.videoStateForwards &&
            this.state.showContent
          }
          timeout={1500}
          classNames="imageNavBackground"
          unmountOnExit
        >
          <div className={styles.iFrameDiv}>
            <iframe
              title="Content iFrame"
              className={styles.iFrameClass}
              autoPlay
              allow="autoplay; fullscreen"
              allowFullScreen
              //src={this.props.plenaryContent}
              src={"https://player.vimeo.com/video/541520247?autoplay=1"}
            ></iframe>
          </div>
        </CSSTransition>

        <CSSTransition
          in={
            this.props.plenaryContent != null &&
            this.props.plenaryContent.length > 5 &&
            !this.state.videoStateForwards &&
            this.state.showContent
          }
          timeout={1500}
          classNames="imageNavBackground"
          unmountOnExit
        >
          <div>
            <div className={styles.slidoContainerDiv}>
              <img
                className={`${styles.hoverHotspot} ${styles.switchToSimTransButton}`}
                src="/hoverShapes/hoverSquare.png"
                alt="Turn on Sim Trans"
                onClick={() => this.setState({ showSimTrans: true })}
              ></img>
              <img
                className={styles.slidoContainer}
                src={window.$videoPath + "visualAssets/en/slidoContainer.png"}
                alt="Slido Container"
              />
            </div>
            <div className={styles.slidoDiv}>
              {this.state.showSimTrans ?
                <iframe
                  title="Slido Iframe"
                  src="https://interprefy.interpret.world/login=widgetdemo"
                  height="100%"
                  width="100%"
                  style={{ minHeight: "560px" }}
                ></iframe> :
                <iframe
                  title="Slido Iframe"
                  src="https://app.sli.do/event/cci0jkqy"
                  height="100%"
                  width="100%"
                  style={{ minHeight: "560px" }}
                ></iframe>}
            </div>
          </div>
        </CSSTransition>
      </div>
    );
  }
}
