import React, { Component } from "react";
import { CSSTransition } from "react-transition-group";
import { Link } from "react-router-dom";
import LobbyMobile from "./LobbyMobile";
import { requestPoints } from "../helpers/requestPoints.js";
import styles from "./Lobby.module.scss";

export default class Lobby extends Component {
  constructor(props) {
    super(props);
    this.state = {
      introVideoComplete: false,
    };
  }

  componentDidMount() {
    requestPoints("visitLobby", "visitLobby");
    const { cookies } = this.props;
    const lobbyVisitedCookie = cookies.get("lobbyVisited");
    if (lobbyVisitedCookie === undefined) {
      this.props.setShowHelpModal(true);
      cookies.set("lobbyVisited", "yes", { path: "/" });
    }

    // Start timeout in case video does not load
    setTimeout(
      function () {
        this.setState({ introVideoComplete: true });
      }.bind(this),
      6000
    );
  }

  componentDidUpdate(prevProps, prevState) {
    // Double check corner menu status is correct
    if (this.props.cornerMenuStatus !== 1) {
      this.props.setCornerMenuStatus(1);
    }
  }


  render() {
    return (
      <div>
        {this.props.showMobile ?
          <LobbyMobile
            setShowAgendaModal={this.props.setShowAgendaModal}
            setShowHelpModal={this.props.setShowHelpModal}
            setShowProfileModal={this.props.setShowProfileModal}
          />
          :
          <div className={styles.masterPageDiv}>
            <video
              className="imageNavBackground"
              autoPlay
              webkit-playsinline="true"
              playsInline
              muted
              id="introVideo"
              onEnded={() => this.setState({ introVideoComplete: true })}
            >
              <source src={window.$videoPath + "visuals/" + this.props.language + "/lobby.webm"} type="video/webm" />
              <source src={window.$videoPath + "visuals/" + this.props.language + "/lobby.mp4"} type="video/mp4" />
            </video>

            <CSSTransition
              in={this.state.introVideoComplete}
              timeout={1500}
              classNames="imageNavBackground"
              unmountOnExit
            >
              <img
                className="imageNavBackground"
                src={window.$videoPath + "visuals/" + this.props.language + "/lobby.jpg"}
                alt="Background"
              ></img>
            </CSSTransition>

            {/** The below is to immediately hide the img if the filename goes to blank otherwise shows missing file whilst fading out */}
            {this.props.lobbyScreen && this.props.lobbyScreen.length > 0 ? (
              <CSSTransition
                in={
                  this.state.introVideoComplete &&
                  this.props.lobbyScreen &&
                  this.props.lobbyScreen.length > 0
                }
                timeout={1500}
                classNames="imageNavBackground"
                unmountOnExit
              >
                    <img src={
                  window.$videoPath +
                  "lobbyScreenFront/nextInPlenary.gif"}
                  className={styles.lobbyVideoScreenBack}
                />
                {/** <video className={styles.lobbyVideoScreenBack} autoPlay webkit-playsinline="true" playsInline muted loop>
                  <source
                    src={
                      window.$videoPath +
                      "lobbyScreen/" +
                      this.props.lobbyScreen +
                      "2.webm"
                    }
                    type="video/webm"
                  />
                  <source
                    src={
                      window.$videoPath +
                      "lobbyScreen/" +
                      this.props.lobbyScreen +
                      "2.mp4"
                    }
                    type="video/mp4"
                  />
                  </video>*/}
              </CSSTransition>
            ) : null}

            {this.props.lobbyScreen && this.props.lobbyScreen.length > 0 ? (
              <CSSTransition
                in={
                  this.state.introVideoComplete &&
                  this.props.lobbyScreen &&
                  this.props.lobbyScreen.length > 0
                }
                timeout={1500}
                classNames="imageNavBackground"
                unmountOnExit
              >
                <img src={
                  window.$videoPath +
                  "lobbyScreenFront/nextInPlenary.gif"}
                  className={styles.lobbyVideoScreenFront}
                />
                {/**<video
                  className={styles.lobbyVideoScreenFront}
                  autoPlay
                  webkit-playsinline="true"
                  playsInline
                  muted
                  loop
                >
                  <source
                    src={
                      window.$videoPath +
                      "lobbyScreenFront/" +
                      this.props.lobbyScreen +
                      "next.webm"
                    }
                    type="video/webm"
                  />
                  <source
                    src={
                      window.$videoPath +
                      "lobbyScreenFront/" +
                      this.props.lobbyScreen +
                      "2.mp4"
                    }
                    type="video/mp4"
                  />
                  </video>*/}
              </CSSTransition>
            ) : null}

            <Link to="/plenary">
              <img
                className={`${styles.hoverHotspot} ${styles.plenaryHoverHotspot}`}
                src="/hoverShapes/hoverSquare.png"
                alt="To plenary"
              ></img>
            </Link>

            <Link to="/posters">
              <img
                className={`${styles.hoverHotspot} ${styles.postersHoverHotspot}`}
                src="/hoverShapes/hoverSquare.png"
                alt="To posters"
              ></img>
            </Link>

            <Link to="./exhibitionIntro">
              <img
                className={`${styles.hoverHotspot} ${styles.exhibitionHoverHotspot}`}
                src="/hoverShapes/hoverSquare.png"
                alt="To exhibition"
              ></img>
            </Link>

            <Link to="/breakouts">
              <img
                className={`${styles.hoverHotspot} ${styles.breakoutsHoverHotspot}`}
                src="/hoverShapes/hoverSquare.png"
                alt="To breakouts"
              ></img>
            </Link>

            <Link to="/networking">
              <img
                className={`${styles.hoverHotspot} ${styles.networkingHoverHotspot}`}
                src="/hoverShapes/hoverSquare.png"
                alt="To networking"
              ></img>
            </Link>

            <img
              className={`${styles.hoverHotspot} ${styles.helpDeskHotSpot}`}
              src="/hoverShapes/hoverSquare.png"
              alt="Help Desk"
              onClick={() => this.props.setShowHelpModal(true)}
            ></img>
          </div>
        }
      </div>
    );
  }
}
