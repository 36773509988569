import React, { Component } from "react";
import { Link } from "react-router-dom";
import styles from "./LobbyMobile.module.scss";

export default class PostersMobile extends Component {

  render () {
    return (
      <div className={styles.mobileListView} >
        <div className={styles.mobileTitleArea}>
          <h1>LOBBY</h1>
        </div>

        <h3 className={styles.mobileLobbyInstructionText}>Select from the following:</h3>

        <div className={styles.lobbyIconGrid}>

          <div
            className={styles.lobbyIconGridElement}
            onClick={() => this.props.setShowAgendaModal(true)}>
            <img
              className={styles.lobbyIcon}
              src={window.$videoPath + "mobileAssets/lobbyIcons/agenda.png"}
              alt="LobbyIcons"
            ></img>
            <h4 className={styles.lobbyIconText}>Agenda</h4>
          </div>

          <Link to="/plenary">
            <div className={styles.lobbyIconGridElement}>
              <img
                className={styles.lobbyIcon}
                src={window.$videoPath + "mobileAssets/lobbyIcons/plenary.png"}
                alt="LobbyIcons"
              ></img>
              <h4 className={styles.lobbyIconText}>Plenary</h4>
            </div>
          </Link>

          <Link to="/breakouts">
            <div className={styles.lobbyIconGridElement}>
              <img
                className={styles.lobbyIcon}
                src={window.$videoPath + "mobileAssets/lobbyIcons/breakouts.png"}
                alt="LobbyIcons"
              ></img>
              <h4 className={styles.lobbyIconText}>Breakouts</h4>
            </div>
          </Link>

          <Link to="/presentersGallery">
            <div className={styles.lobbyIconGridElement}>
              <img
                className={styles.lobbyIcon}
                src={window.$videoPath + "mobileAssets/lobbyIcons/speakers.png"}
                alt="LobbyIcons"
              ></img>
              <h4 className={styles.lobbyIconText}>Speakers</h4>
            </div>
          </Link>

          <Link to="/gallery">
            <div className={styles.lobbyIconGridElement}>
              <img
                className={styles.lobbyIcon}
                src={window.$videoPath + "mobileAssets/lobbyIcons/attendees.png"}
                alt="LobbyIcons"
              ></img>
              <h4 className={styles.lobbyIconText}>Attendees</h4>
            </div>
          </Link>

          <Link to="/networking">
            <div className={styles.lobbyIconGridElement}>
              <img
                className={styles.lobbyIcon}
                src={window.$videoPath + "mobileAssets/lobbyIcons/networking.png"}
                alt="LobbyIcons"
              ></img>
              <h4 className={styles.lobbyIconText}>Networking</h4>
            </div>
          </Link>

          <Link to="/exhibition">
            <div className={styles.lobbyIconGridElement}>
              <img
                className={styles.lobbyIcon}
                src={window.$videoPath + "mobileAssets/lobbyIcons/exhibition.png"}
                alt="LobbyIcons"
              ></img>
              <h4 className={styles.lobbyIconText}>Exhibition</h4>
            </div>
          </Link>


          <Link to="/onDemand">
            <div className={styles.lobbyIconGridElement}>
              <img
                className={styles.lobbyIcon}
                src={window.$videoPath + "mobileAssets/lobbyIcons/onDemand.png"}
                alt="LobbyIcons"
              ></img>
              <h4 className={styles.lobbyIconText}>OnDemand</h4>
            </div>
          </Link>

          <Link to="/meetings">
            <div className={styles.lobbyIconGridElement}>
              <img
                className={styles.lobbyIcon}
                src={window.$videoPath + "mobileAssets/lobbyIcons/meetings.png"}
                alt="LobbyIcons"
              ></img>
              <h4 className={styles.lobbyIconText}>Meetings</h4>
            </div>
          </Link>

          <Link to="/posters">
            <div className={styles.lobbyIconGridElement}>
              <img
                className={styles.lobbyIcon}
                src={window.$videoPath + "mobileAssets/lobbyIcons/posters.png"}
                alt="LobbyIcons"
              ></img>
              <h4 className={styles.lobbyIconText}>Posters</h4>
            </div>
          </Link>

          <div
            className={styles.lobbyIconGridElement}
            onClick={() => this.props.setShowProfileModal(true)}>
            <img
              className={styles.lobbyIcon}
              src={window.$videoPath + "mobileAssets/lobbyIcons/profile.png"}
              alt="LobbyIcons"
            ></img>
            <h4 className={styles.lobbyIconText}>Profile</h4>
          </div>

          <div
            className={styles.lobbyIconGridElement}
            onClick={() => this.props.setShowHelpModal(true)}>
            <img
              className={styles.lobbyIcon}
              src={window.$videoPath + "mobileAssets/lobbyIcons/help.png"}
              alt="LobbyIcons"
            ></img>
            <h4 className={styles.lobbyIconText}>Help</h4>
          </div>

        </div>
      </div>
    );
  }
}
