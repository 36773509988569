import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import styles from "./ExhibitionHallMobile.module.scss";

export default class ExhibitionHallMobile extends Component {

  render () {
    return (
      <div className={styles.mobileListView} >
        <div className={styles.mobileTitleArea}>
          <h1 className={styles.mobileTitleText}>EXHIBITION HALL {this.props.hallID}</h1>
          <Link to="/exhibition"><Button>Back to Exhibition</Button></Link>
        </div>

        {this.props.hallID === "1" ?
          <>
            <Link to="/exhibitionStand/601db92f87891434a8d1bee9">
              <div
                className={styles.mobileListItem}
              >
                <h3 className={styles.mobileListTitle}>Exhibition Stand 1</h3>
              </div>
            </Link>

            <Link to="/exhibitionStand/601db92f87891434a8d1bee6">
              <div
                className={styles.mobileListItem}
              >
                <h3 className={styles.mobileListTitle}>Exhibition Stand 2</h3>
              </div>
            </Link>

            <Link to="/exhibitionSearch">
              <div
                className={styles.mobileListItem}
              >
                <h3 className={styles.mobileListTitle}>Search Exhibition</h3>
              </div>
            </Link>
          </>
          : null}
      </div>
    );
  }
}
