import React, { Component } from "react";
import { Image, Transformation } from "cloudinary-react";
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import { SketchPicker } from 'react-color'
import styles from "./EditExhibitionStand.module.scss";

export default class EditExhibitionStand extends Component {
  constructor() {
    super();
    this.state = {
      exhibitionStandID: "",
      standName: "",
      standSummary: "",
      standSubtitle: "",
      panelColor: "#000000",
      showResourceArea: true,
      logoAssetID: "",
      fileInputDisabled: false,
    };
  }

  componentDidMount () {
    this.retrieveExhibitionStand();
  }

  retrieveExhibitionStand () {
    fetch("/api/getExhibitionStandAdmin?exhibitionStandID=" + this.props.match.params.exhibitionStandID)
      .then((res) => res.json())
      .then((data) => this.loadResponseIntoState(data));
  }

  loadResponseIntoState (data) {
    this.setState({
      exhibitionStandID: data.exhibitionStand._id,
      standName: data.exhibitionStand.standName,
      standSummary: data.exhibitionStand.standSummary,
      standSubtitle: data.exhibitionStand.standSubtitle,
      panelColor: data.exhibitionStand.panelColor,
      panelTextColor: data.exhibitionStand.panelTextColor,
      deskFrontColor: data.exhibitionStand.deskFrontColor,
      logoAssetID: data.exhibitionStand.logoAssetID,
      backPanelAssetID: data.exhibitionStand.backPanelAssetID,
      deskPanelAssetID: data.exhibitionStand.deskPanelAssetID,
      showResourceArea: data.exhibitionStand.showResourceArea,
      showQAndAArea: data.exhibitionStand.showQAndAArea,
      showTextChatArea: data.exhibitionStand.showTextChatArea,
      showLeaveDetailsArea: data.exhibitionStand.showLeaveDetailsArea,
      mainVideoID: data.exhibitionStand.mainVideoID,
      resourceVideo1ID: data.exhibitionStand.resourceVideo1ID,
      resourceVideo2ID: data.exhibitionStand.resourceVideo2ID,
      resourceLink1Title: data.exhibitionStand.resourceLink1Title,
      resourceLink1: data.exhibitionStand.resourceLink1,
      resourceLink2Title: data.exhibitionStand.resourceLink2Title,
      resourceLink2: data.exhibitionStand.resourceLink2,
      resourceLink3Title: data.exhibitionStand.resourceLink3Title,
      resourceLink3: data.exhibitionStand.resourceLink3,
      resourceLink4Title: data.exhibitionStand.resourceLink4Title,
      resourceLink4: data.exhibitionStand.resourceLink4,
      resourceLink5Title: data.exhibitionStand.resourceLink5Title,
      resourceLink5: data.exhibitionStand.resourceLink5,
      resourceLink6Title: data.exhibitionStand.resourceLink6Title,
      resourceLink6: data.exhibitionStand.resourceLink6,
      resourceLink7Title: data.exhibitionStand.resourceLink7Title,
      resourceLink7: data.exhibitionStand.resourceLink7,
      resourceLink8Title: data.exhibitionStand.resourceLink8Title,
      resourceLink8: data.exhibitionStand.resourceLink8,
      exhibitorEmail: data.exhibitionStand.exhibitorEmail,
    });
  }

  updateInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleShowResourceAreaChange = (value) => {
    this.setState({
      showResourceArea: value,
    });
  };

  handleShowQAndAAreaChange = (value) => {
    this.setState({
      showQAndAArea: value,
    });
  };

  handleShowTextChatAreaChange = (value) => {
    this.setState({
      showTextChatArea: value,
    });
  };

  handleShowLeaveDetailsAreaChange = (value) => {
    this.setState({
      showLeaveDetailsArea: value,
    });
  };

  handleUpdatePanelColor = (color) => {
    this.setState({ panelColor: color.hex });
  }

  handleUpdatePanelTextColor = (color) => {
    this.setState({ panelTextColor: color.hex });
  }

  handleCompanyLogoUpload = (event) => {
    this.setState({ fileInputDisabled: true });
    const data = new FormData();
    data.append("file", event.target.files[0]);

    fetch("/api/uploadImage", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          logoAssetID: data.publicID,
        });
        //console.log("Returned upload ID: " + this.state.logoAssetID);
        this.setState({ fileInputDisabled: false });
      });
  };

  handleDocumentUpload = (event) => {
    this.setState({ fileInputDisabled: true });
    const data = new FormData();
    data.append("file", event.target.files[0]);

    fetch("/api/uploadToS3", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        /**this.setState({
          logoAssetID: data.publicID,
        });
        console.log("Returned upload ID: " + this.state.logoAssetID);*/
        //console.log("Success");
        this.setState({ fileInputDisabled: false });
      });
  };



  saveSettings = (event) => {
    event.preventDefault();
    fetch("/api/updateExhibitionStand", {
      method: "POST",
      body: JSON.stringify(this.state),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 200) {
          //console.log("Saved successfully");
        } else {
          const error = new Error(res.error);
          throw error;
        }
      })
      .catch((err) => {
        console.error(err);
        alert("Error updating settings -  please try again");
      });
  };

  render () {
    return (
      <div className={styles.background}>
        <div className={styles.remoteControlArea}>
          <h1>Edit Exhibition Stand</h1>
          <form onSubmit={this.saveSettings}>
            <br />


            <h3>Stand Name</h3>
            <input
              type="text"
              name="standName"
              size={100}
              placeholder="Stand Name"
              value={this.state.standName}
              onChange={this.updateInput}
            />
            <br /> <br />
            <h3>Stand Summary</h3>
            <input
              type="text"
              name="standSummary"
              size={100}
              placeholder="Stand Summary"
              value={this.state.standSummary}
              onChange={this.updateInput}
            />

            <br /> <br />
            <h3>Stand Subtitle</h3>
            <input
              type="text"
              name="standSubtitle"
              size={100}
              placeholder="Stand Subtitle"
              value={this.state.standSubtitle}
              onChange={this.updateInput}
            />

            <br /> <br />
            <h3>Show Resource Area</h3>
            <ToggleButtonGroup
              type="radio"
              value={this.state.showResourceArea}
              name="showResourceArea"
              onChange={this.handleShowResourceAreaChange}
            >
              <ToggleButton value={true}>Show</ToggleButton>
              <ToggleButton value={false}>Hide</ToggleButton>
            </ToggleButtonGroup>

            <br /> <br />
            <h3>Show Q&A Area</h3>
            <ToggleButtonGroup
              type="radio"
              value={this.state.showQAndAArea}
              name="showQAndAArea"
              onChange={this.handleShowQAndAAreaChange}
            >
              <ToggleButton value={true}>Show</ToggleButton>
              <ToggleButton value={false}>Hide</ToggleButton>
            </ToggleButtonGroup>

            <br /> <br />
            <h3>Show Text Chat Area</h3>
            <ToggleButtonGroup
              type="radio"
              value={this.state.showTextChatArea}
              name="showTextChatArea"
              onChange={this.handleShowTextChatAreaChange}
            >
              <ToggleButton value={true}>Show</ToggleButton>
              <ToggleButton value={false}>Hide</ToggleButton>
            </ToggleButtonGroup>

            <br /> <br />
            <h3>Show Leave Details Area</h3>
            <ToggleButtonGroup
              type="radio"
              value={this.state.showLeaveDetailsArea}
              name="showLeaveDetailsArea"
              onChange={this.handleShowLeaveDetailsAreaChange}
            >
              <ToggleButton value={true}>Show</ToggleButton>
              <ToggleButton value={false}>Hide</ToggleButton>
            </ToggleButtonGroup>


            <br /> <br />
            <h3>Panel Colour</h3>
            <SketchPicker
              color={this.state.panelColor}
              onChangeComplete={this.handleUpdatePanelColor}
            />

            <br /> <br />
            <h3>Panel Text Colour</h3>
            <SketchPicker
              color={this.state.panelTextColor}
              onChangeComplete={this.handleUpdatePanelTextColor}
            />

            <br /> <br />
            <h3>Desk Front Color</h3>
            <SketchPicker
              color={this.state.deskFrontColor}
              onChangeComplete={this.handleUpdateDeskFrontColor}
            />


            <br /> <br />
            <h3>Upload company logo</h3>
            <label className={styles.customFileUpload}>
              <img
                className={styles.uploadButton}
                src={window.$videoPath + "icons/profile/upload.png"}
                alt="Upload"
              ></img>
              <input
                className={styles.fileInput}
                name="companyLogoPicture"
                type="file"
                disabled={this.state.fileInputDisabled}
                accept="image/*"
                onChange={this.handleCompanyLogoUpload}
              />
              <Image
                cloudName="beehive35j35jq"
                publicId={this.state.logoAssetID}
                width="150"
                secure={true}
              >
                <Transformation
                  height="150"
                  width="150"
                  crop="pad"
                  radius="20"
                />
              </Image>
            </label>
            <br /> <br />
            <h3>Main Video ID</h3>
            <input
              type="text"
              name="mainVideoID"
              size={100}
              placeholder="Main Video ID"
              value={this.state.mainVideoID}
              onChange={this.updateInput}
            />
            <br /> <br />

            <h2>Resource Area:</h2>
            <table><tbody>
              <tr>
                <td>Resource Area Video 1 ID:</td>
                <td>
                  <input
                    type="text"
                    name="resourceVideo1ID"
                    size={100}
                    placeholder="Resource Video 1 ID"
                    value={this.state.resourceVideo1ID}
                    onChange={this.updateInput}
                  />
                </td>
              </tr>
              <tr>
                <td>Resource Area Video 2 ID:</td>
                <td>
                  <input
                    type="text"
                    name="resourceVideo2ID"
                    size={100}
                    placeholder="Resource Video 2 ID"
                    value={this.state.resourceVideo2ID}
                    onChange={this.updateInput}
                  />
                </td>
              </tr>
              <tr>
                <td>Resource Area Link 1 Title:</td>
                <td>
                  <input
                    type="text"
                    name="resourceLink1Title"
                    size={100}
                    placeholder="Resource Link 1 Title"
                    value={this.state.resourceLink1Title}
                    onChange={this.updateInput}
                  />
                </td>
              </tr>
              <tr>
                <td>Resource Area Link 1:</td>
                <td>
                  <input
                    type="text"
                    name="resourceLink1"
                    size={100}
                    placeholder="Resource Link 1"
                    value={this.state.resourceLink1}
                    onChange={this.updateInput}
                  />
                </td>
              </tr>
              <tr>
                <td>Resource Area Link 2 Title:</td>
                <td>
                  <input
                    type="text"
                    name="resourceLink2Title"
                    size={100}
                    placeholder="Resource Link 2 Title"
                    value={this.state.resourceLink2Title}
                    onChange={this.updateInput}
                  />
                </td>
              </tr>
              <tr>
                <td>Resource Area Link 2:</td>
                <td>
                  <input
                    type="text"
                    name="resourceLink2"
                    size={100}
                    placeholder="Resource Link 2"
                    value={this.state.resourceLink2}
                    onChange={this.updateInput}
                  />
                </td>
              </tr>
              <tr>
                <td>Resource Area Link 3 Title:</td>
                <td>
                  <input
                    type="text"
                    name="resourceLink3Title"
                    size={100}
                    placeholder="Resource Link 3 Title"
                    value={this.state.resourceLink3Title}
                    onChange={this.updateInput}
                  />
                </td>
              </tr>
              <tr>
                <td>Resource Area Link 3:</td>
                <td>
                  <input
                    type="text"
                    name="resourceLink3"
                    size={100}
                    placeholder="Resource Link 3"
                    value={this.state.resourceLink3}
                    onChange={this.updateInput}
                  />
                </td>
              </tr>
              <tr>
                <td>Resource Area Link 4 Title:</td>
                <td>
                  <input
                    type="text"
                    name="resourceLink4Title"
                    size={100}
                    placeholder="Resource Link 4 Title"
                    value={this.state.resourceLink4Title}
                    onChange={this.updateInput}
                  />
                </td>
              </tr>
              <tr>
                <td>Resource Area Link 4:</td>
                <td>
                  <input
                    type="text"
                    name="resourceLink4"
                    size={100}
                    placeholder="Resource Link 4"
                    value={this.state.resourceLink4}
                    onChange={this.updateInput}
                  />
                </td>
              </tr>
              <tr>
                <td>Resource Area Link 5 Title:</td>
                <td>
                  <input
                    type="text"
                    name="resourceLink5Title"
                    size={100}
                    placeholder="Resource Link 5 Title"
                    value={this.state.resourceLink5Title}
                    onChange={this.updateInput}
                  />
                </td>
              </tr>
              <tr>
                <td>Resource Area Link 5:</td>
                <td>
                  <input
                    type="text"
                    name="resourceLink5"
                    size={100}
                    placeholder="Resource Link 5"
                    value={this.state.resourceLink5}
                    onChange={this.updateInput}
                  />
                </td>
              </tr>
              <tr>
                <td>Resource Area Link 6 Title:</td>
                <td>
                  <input
                    type="text"
                    name="resourceLink6Title"
                    size={100}
                    placeholder="Resource Link 6 Title"
                    value={this.state.resourceLink6Title}
                    onChange={this.updateInput}
                  />
                </td>
              </tr>
              <tr>
                <td>Resource Area Link 6:</td>
                <td>
                  <input
                    type="text"
                    name="resourceLink6"
                    size={100}
                    placeholder="Resource Link 6"
                    value={this.state.resourceLink6}
                    onChange={this.updateInput}
                  />
                </td>
              </tr>
              <tr>
                <td>Resource Area Link 7 Title:</td>
                <td>
                  <input
                    type="text"
                    name="resourceLink7Title"
                    size={100}
                    placeholder="Resource Link 7 Title"
                    value={this.state.resourceLink7Title}
                    onChange={this.updateInput}
                  />
                </td>
              </tr>
              <tr>
                <td>Resource Area Link 7:</td>
                <td>
                  <input
                    type="text"
                    name="resourceLink7"
                    size={100}
                    placeholder="Resource Link 7"
                    value={this.state.resourceLink7}
                    onChange={this.updateInput}
                  />
                </td>
              </tr>
              <tr>
                <td>Resource Area Link 8 Title:</td>
                <td>
                  <input
                    type="text"
                    name="resourceLink8Title"
                    size={100}
                    placeholder="Resource Link 8 Title"
                    value={this.state.resourceLink8Title}
                    onChange={this.updateInput}
                  />
                </td>
              </tr>
              <tr>
                <td>Resource Area Link 8:</td>
                <td>
                  <input
                    type="text"
                    name="resourceLink1"
                    size={100}
                    placeholder="Resource Link 1"
                    value={this.state.resourceLink8}
                    onChange={this.updateInput}
                  />
                </td>
              </tr>
            </tbody></table>
            {/**<h3>Upload PDF</h3>
            <label className={styles.customFileUpload}>
              <img
                className={styles.uploadButton}
                src={window.$videoPath + "icons/profile/upload.png"}
                alt="Upload"
              ></img>
              <input
                className={styles.fileInput}
                name="documentUpload"
                type="file"
                disabled={this.state.fileInputDisabled}
                accept=".pdf"
                onChange={this.handleDocumentUpload}
              />
            </label>*/}
            <br /> <br />
            {<button type="submit">Submit</button>}

            <br /> <br />
            <br /> <br />
          </form>
        </div>
      </div>
    );
  }
}