import React from "react";
import ReactDOM from "react-dom";
import './i18n';
import { CookiesProvider } from "react-cookie";

import "bootstrap/dist/css/bootstrap.min.css";

import App from "./components/App";

window.$videoPath = "https://lu-beehive-osprey.s3.eu-west-2.amazonaws.com/"; //global variable

ReactDOM.render(
  <CookiesProvider>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </CookiesProvider>,
  document.getElementById("root")
);
