import React from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { requestPoints } from "../helpers/requestPoints.js";
import styles from "./NetworkingMobile.module.scss";

export default function Breakouts (props) {

  return (
    <div className={styles.mobileListView} >
      <div className={styles.mobileTitleArea}>
        <h1 className={styles.mobileTitleText}>NETWORKING</h1>
        <Link to="/lobby"><Button>Back to Lobby</Button></Link>
      </div>

      <Link to="/gallery">
        <div
          className={styles.mobileListItem}
        >
          <h3 className={styles.mobileListTitle}>1:1 Gallery</h3>
        </div>
      </Link>
      <Link to="/roundtables">
        <div
          className={styles.mobileListItem}
        >
          <h3 className={styles.mobileListTitle}>Roundtables</h3>
        </div>
      </Link>

    </div>
  );
}
