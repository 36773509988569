import React, { Component } from "react";
import { CSSTransition } from "react-transition-group";
import { Link } from "react-router-dom";
import NetworkingMobile from "./NetworkingMobile";
import { requestPoints } from "./../helpers/requestPoints.js";
import styles from "./Networking.module.scss";

export default class Networking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      introVideoComplete: false,
    };
  }

  componentDidMount () {
    requestPoints("visitNetworkingLounge", "visitNetworkingLounge");

    // Start timeout in case video does not load
    setTimeout(
      function () {
        this.setState({ introVideoComplete: true });
      }.bind(this),
      6000
    );
  }

  render () {
    return (
      <div>
        {this.props.showMobile ?
          <NetworkingMobile />
          :
          <div className={styles.masterPageDiv}>
            <video
              className="imageNavBackground"
              autoPlay
              webkit-playsinline="true"
              playsInline
              muted
              id="introVideo"
              onEnded={() => this.setState({ introVideoComplete: true })}
            >
              <source
                src={window.$videoPath + "visuals/" + this.props.language + "/networking.webm"}
                type="video/webm"
              />
              <source
                src={window.$videoPath + "visuals/" + this.props.language + "/networking.mp4"}
                type="video/mp4"
              />
            </video>

            <CSSTransition
              in={this.state.introVideoComplete}
              timeout={1500}
              classNames="imageNavBackground"
              unmountOnExit
            >
              <img
                className="imageNavBackground"
                src={window.$videoPath + "visuals/" + this.props.language + "/networking.jpg"}
                alt="Background"
              ></img>
            </CSSTransition>

            {this.state.introVideoComplete ? (
              <div>
                {/**<div className={styles.videoDiv}>
                  <iframe
                    title="Content iFrame"
                    className={styles.iFrameClass}
                    autoPlay
                    allow="autoplay; fullscreen"
                    allowFullScreen
                    src={"https://player.vimeo.com/video/540208530?autoplay=1"}
                  ></iframe>
                </div>*/}
                <div>
                  <Link to="/gallery">
                    <img
                      className={`${styles.hoverHotspot} ${styles.galleryHoverHotspot}`}
                      src="/hoverShapes/hoverSquare.png"
                      alt="To Gallery"
                    ></img>
                  </Link>
                  <Link to="/roundtables">
                    <img
                      className={`${styles.hoverHotspot} ${styles.roundtablesHoverHotspot}`}
                      src="/hoverShapes/hoverSquare.png"
                      alt="To Roundtables"
                    ></img>
                  </Link>
                </div>
                <Link to="/lobby">
                  <img
                    className={`${styles.hoverHotspot} ${styles.unzoomHoverHotspot}`}
                    src="/hoverShapes/hoverSquare.png"
                    alt="To Lobby"
                  ></img>
                </Link>
              </div>

            ) : null}
          </div>
        }
      </div>
    );
  }
}
