import React, { Component } from "react";
import { CSSTransition } from "react-transition-group";
import { Link } from "react-router-dom";
import PosterModal from "./PosterModal";
import Button from "react-bootstrap/Button";
import PostersMobile from "./PostersMobile";
import { requestPoints } from "./../helpers/requestPoints.js";
import styles from "./Posters.module.scss";

export default class Posters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      introVideoComplete: false,
      showPosterModal: false,
      posterGalleryContent: [],
      selectedPoster: null,
      hoverPosterItem: null,
    };
  }

  componentDidMount () {
    // Start timeout in case video does not load
    setTimeout(
      function () {
        this.setState({ introVideoComplete: true });
      }.bind(this),
      500
    );
    // Load posters from db
    this.loadCurrentDatabaseContent();
  }

  componentDidUpdate (prevProps, prevState) {
    // Double check corner menu status is correct
    if (this.props.cornerMenuStatus !== 1) {
      this.props.setCornerMenuStatus(1);
    }
  }

  loadCurrentDatabaseContent () {
    fetch("/api/getPosterGalleryContent")
      .then((res) => res.json())
      .then((data) => this.setState({ posterGalleryContent: data }));
  }

  showPoster (poster) {
    requestPoints("viewPoster", poster._id);
    this.setState({ selectedPoster: poster, showPosterModal: true });
    this.setState({ showPrimaryTab: true });
  }

  showTitleModal = (hoverPosterItem) => {
    //console.log("Show modal for " + hoverPosterItem.posterName);
    this.setState({ hoverPosterItem: hoverPosterItem });
  };

  render () {
    const posterHoverAreas = this.state.posterGalleryContent.map(
      (posterGalleryItem) => {
        return (
          <div key={posterGalleryItem._id}>
            <img
              style={{
                left: posterGalleryItem.left + "%",
                width: posterGalleryItem.width + "%",
                height: posterGalleryItem.height + "%",
                top: posterGalleryItem.top + "%",
              }}
              className={styles.posterHoverHotspot}
              src="/hoverShapes/hoverSquare.png"
              alt="Show poster"
              onClick={() => this.showPoster(posterGalleryItem)}
              onMouseOver={() => this.showTitleModal(posterGalleryItem)}
            ></img>
          </div>
        );
      }
    );

    return (
      <div>
        {this.props.showMobile ?
          <PostersMobile
            posterGalleryContent={this.state.posterGalleryContent}
            language={this.props.language}
            showMobile={this.props.showMobile}
          />
          :
          <div className={styles.masterPageDiv}>
            <video
              className="imageNavBackground"
              autoPlay
              webkit-playsinline="true"
              playsInline
              muted
              id="introVideo"
              onEnded={() => this.setState({ introVideoComplete: true })}
            >
              <source
                src={window.$videoPath + "visuals/" + this.props.language + "/posters.webm"}
                type="video/webm"
              />
              <source src={window.$videoPath + "visuals/" + this.props.language + "/posters.mp4"} type="video/mp4" />
            </video>

            <CSSTransition
              in={this.state.introVideoComplete}
              timeout={1500}
              classNames="imageNavBackground"
              unmountOnExit
            >
              <img
                className="imageNavBackground"
                src={window.$videoPath + "visuals/" + this.props.language + "/posters.jpg"}
                alt="Background"
              ></img>
            </CSSTransition>

            <PosterModal
              showPosterModal={this.state.showPosterModal}
              closePosterModal={() => this.setState({ showPosterModal: false })}
              poster={this.state.selectedPoster}
              language={this.props.language}
              showMobile={this.props.showMobile}
            />

            {this.state.introVideoComplete ? posterHoverAreas : null}

            {this.state.hoverPosterItem ? (
              <div
                className={styles.posterNamePopUpContainer}
                style={{
                  left: this.state.hoverPosterItem.left + "%",
                  top: this.state.hoverPosterItem.top - 50 + "%",
                }}
              >
                <div className={styles.posterNamePopUp}>
                  {this.state.hoverPosterItem.posterName}
                  <br />
                  <br />
                  <Button
                    variant="success"
                    className={styles.joinButton}
                    onClick={() => this.showPoster(this.state.hoverPosterItem)}
                  >
                    View
                  </Button>
                </div>
              </div>
            ) : null}

            <Link to="/lobby">
              <img
                className={`${styles.hoverHotspot} ${styles.lobbyHoverHotspot}`}
                src="/hoverShapes/hoverSquare.png"
                alt="Navigate to Lobby"
              ></img>
            </Link>

            <Link to="/plenary">
              <img
                className={`${styles.hoverHotspot} ${styles.plenaryHoverHotspot}`}
                src="/hoverShapes/hoverSquare.png"
                alt="Navigate to Lobby"
              ></img>
            </Link>

            <Link to="/breakouts">
              <img
                className={`${styles.hoverHotspot} ${styles.breakoutsHoverHotspot}`}
                src="/hoverShapes/hoverSquare.png"
                alt="To breakouts"
              ></img>
            </Link>

            <Link to="/networking">
              <img
                className={`${styles.hoverHotspot} ${styles.networkingHoverHotspot}`}
                src="/hoverShapes/hoverSquare.png"
                alt="To networking"
              ></img>
            </Link>

            <img
              className={`${styles.hoverHotspot} ${styles.helpDeskHotSpot}`}
              src="/hoverShapes/hoverSquare.png"
              alt="Help Desk"
              onClick={() => this.props.setShowHelpModal(true)}
            ></img>
          </div>
        }
      </div>
    );
  }
}
