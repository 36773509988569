import React, { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { Link } from "react-router-dom";
import styles from "./Breakouts4.module.scss";

export default function Breakouts (props) {
  const [introVideoComplete, setIntroVideoComplete] = useState(false);

  useEffect(() => {
    console.log("Remounting Breakouts.js");
    // Start timeout in case video does not load
    setTimeout(function () {
      setIntroVideoComplete(true);
    }, 6000);
  }, []);

  return (
    <div className="imageNavDiv">
      {props.playVideo ? (
        <div>
          <video
            className="imageNavBackground"
            autoPlay
            id="myVideo"
            muted
            onEnded={() => setIntroVideoComplete(true)}
          >
            <source
              src={window.$videoPath + "demoVenues/warehouse/altBreakouts4.webm"}
              type="video/webm"
            />
            <source
              src={window.$videoPath + "demoVenues/warehouse/altBreakouts4.mp4"}
              type="video/mp4"
            />
          </video>
          <CSSTransition
            in={introVideoComplete}
            timeout={1500}
            classNames="imageNavBackground"
            unmountOnExit
          >
            <img
              className="imageNavBackground"
              src={window.$videoPath + "demoVenues/warehouse/altBreakouts4.jpg"}
              alt="Background"
            ></img>
          </CSSTransition>
        </div>
      ) : (
        <img
          className="imageNavBackground"
          src={window.$videoPath + "demoVenues/warehouse/altBreakouts4.jpg"}
          alt="Background"
        ></img>
      )}

      <Link to="/lobby4">
        <img
          className={`${styles.hoverHotspot} ${styles.lobbyHoverHotspot}`}
          src="/hoverShapes/hoverSquare.png"
          alt="Navigate to Lobby"
        ></img>
      </Link>

      <Link to="/breakout4/5f240c59a23ba10b65f7f67d">
        <img
          className={`${styles.hoverHotspot} ${styles.smallBreakout1HoverHotspot}`}
          src="/hoverShapes/hoverSquare.png"
          alt="Navigate to small breakout 1"
        ></img>
      </Link>

      <Link to="/breakout/5f24170009a67f40c20d1b8b">
        <img
          className={`${styles.hoverHotspot} ${styles.smallBreakout2HoverHotspot}`}
          src="/hoverShapes/hoverSquare.png"
          alt="Navigate to small breakout 2"
        ></img>
      </Link>

      <Link to="/zoomBreakout">
        <img
          className={`${styles.hoverHotspot} ${styles.smallBreakout3HoverHotspot}`}
          src="/hoverShapes/hoverSquare.png"
          alt="Navigate to small breakout 3"
        ></img>
      </Link>

      <Link to="/largeBreakout/5f383a5cc3cb591bbfb17c2b">
        <img
          className={`${styles.hoverHotspot} ${styles.smallBreakout4HoverHotspot}`}
          src="/hoverShapes/hoverSquare.png"
          alt="Navigate to small breakout 4"
        ></img>
      </Link>

      <Link to="/resourceLibrary">
        <img
          className={`${styles.hoverHotspot} ${styles.largeBreakoutHoverHotspot}`}
          src="/hoverShapes/hoverSquare.png"
          alt="Navigate to resource library"
        ></img>
      </Link>
    </div>
  );
}
