import React, { Component } from "react";

import MessageForm from "./MessageForm";
import MessageList from "./MessageList";

import { Image, Transformation } from "cloudinary-react";
import { CSSTransition } from "react-transition-group";

import styles from "./ChatFunction.module.scss";
import circlesStyles from "./CirclesOverlay.module.scss";
const Chat = require("twilio-chat");

export default class ChatFunction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      channel: null,
      members: [],
      userNamesList: [],
      localMemberArray: [],
      circlesVisible: false,
      newMessages: false,
    };
  }

  componentDidMount = () => {
    this.getToken()
      .then(this.createChatClient)
      .then(this.joinSelectedChannel)
      .catch((error) => {
        this.addMessage({ body: `Error: ${error.message}` });
      });

    // Pull in initial list of names and profile pictures
    fetch("/api/getUserListForNames")
      .then((res) => res.json())
      .then((data) => this.setState({ userNamesList: data }));
  };

  createChatClient = (token) => {
    return new Promise((resolve, reject) => {
      resolve(Chat.Client.create(token.tokenValue));
    });
  };

  getToken = () => {
    return new Promise((resolve, reject) => {
      this.setState({
        //messages: [...this.state.messages, { body: `Connecting...` }],
      });

      fetch("/api/twilioChatTokenWithIDAsIdentity")
        .then((res) => res.json())
        .then((token) => {
          console.log("Received token: " + token.tokenValue);
          resolve(token);
          this.setState({ username: token.identity });
        })
        .catch((err) => {
          console.error(err);
          reject(Error("Failed to connect"));
        });
    });
  };

  // Used to get users names and profile pictures on joining meeting and as others join
  lookupUserRecord = (userID) => {
    // Pull current members list for names and profile pictures
    fetch("/api/getUserListForNames")
      .then((res) => res.json())
      .then((data) => this.setState({ userNamesList: data }));

    var userFound = this.state.userNamesList.find(key => key._id === userID);
    return (userFound)

  }

  // Used to get users names and profile pictures on joining meeting and as others join
  lookupUserRecordFromLocalMembers = (userID) => {
    var userFound = this.state.localMemberArray.find(key => key._id === userID);
    return (userFound)

  }


  getMemberColor = (userID) => {
    const lastDigit = userID.slice(userID.length - 1);
    switch (lastDigit) {
      case "1":
      case "6":
      case "a":
      case "f":
        return "#3FA9F5";
      case "2":
      case "7":
      case "b":
        return "#F15A24";
      case "3":
      case "8":
      case "c":
        return "#D4145A";
      case "4":
      case "9":
      case "d":
        return "#662D91";
      case "5":
      case "e":
        return "#009245";
      default:
        return "#009245";
    }

  }

  updateMembersList = () => {

    // Clear local members array
    this.setState({ localMemberArray: [] })
    this.state.channel.getMembers().then((members) => {
      this.setState({ members: members });
      members.forEach(member => {
        var userRecord = this.lookupUserRecord(member.identity);
        console.log("CCC")
        console.log(userRecord);
        // Lookup member from gallery and add to list
        if (userRecord === undefined) {
          console.log("No matching user found for " + member.identity);
        } else {
          console.log("Match found for " + member.identity);
          console.log(userRecord);
          const memberColor = this.getMemberColor(member.identity);
          console.log("Color: " + memberColor);
          this.setState({ localMemberArray: this.state.localMemberArray.concat([{ _id: userRecord._id, firstName: userRecord.firstName, surname: userRecord.surname, userProfilePictureID: userRecord.userProfilePictureID, color: memberColor }]) })
        }
        //this.addMessage({ author: "Meeting Helper", body: userRecord.firstName + " is already in the meeting" });
      });
    });
    console.log("DDD");


  }

  joinSelectedChannel = (chatClient) => {
    return new Promise((resolve, reject) => {
      chatClient
        .getPublicChannelDescriptors()
        .then(() => {
          console.log("Checking for channel: " + this.props.chatChannel);
          chatClient
            .getChannelByUniqueName(this.props.chatChannel)
            .then((channel) => {
              console.log("Foundchannel");
              //this.addMessage({ body: "Joining channel..." });
              this.setState({ channel });

              /**chatClient.on('channelJoined', function (channel) {
                console.log('Joined channel ' + channel.friendlyName);
                console.log("XXXXX");
                console.log(channel);
              });*/

              console.log("XXX9");
              // Leave first in case we are already still joined
              channel.leave().then(() => {
                channel
                  .join()
                  .then(() => {
                    console.log("XXX10");
                    this.addMessage({
                      //body: `Joined channel as ${this.state.username}`,
                    });

                    window.addEventListener("beforeunload", () =>
                      channel.leave()
                    );

                    channel.on("messageAdded", ({ author, body }) => {
                      var authorName = this.lookupUserRecordFromLocalMembers(author);
                      console.log(authorName.firstName);
                      this.addMessage({ author: authorName.firstName, body, color: authorName.color });
                      console.log("AAA " + authorName.firstName + " BBB " + authorName.color)
                      this.setState({ newMessages: true });
                    });

                    channel.on("memberJoined", (member) => {
                      //this.addMessage({ body: `${member.identity} has joined the channel.` });
                      console.log(member);
                      this.updateMembersList();
                    });


                    channel.on("memberLeft", (member) => {
                      //this.addMessage({ body: `${member.identity} has left the channel.` });
                      this.updateMembersList();
                    });

                    {/**channel.getMessages().then((messages) => {
                      const totalMessages = messages.items.length;
                      var i;
                      for (i = 0; i < totalMessages; i++) {
                        const message = messages.items[i];
                        console.log('Author:' + message.author);
                        var authorName = this.lookupUserRecord(message.author);
                        console.log("Get author details: " + authorName.firstName + " - " + authorName._id);
                        var userColor = this.getMemberColor(authorName._id);
                        this.addMessage({ author: authorName.firstName, body: message.body, color: userColor });
                      }
                      console.log('Total Messages:' + totalMessages);
                    });*/}

                    this.updateMembersList();

                  })
                  .catch(() => reject(Error("Could not join channel.")));
              })


              resolve(channel);
            })
            .catch(() => this.createNewChannel(chatClient));
        })
        .catch(() => reject(Error("Could not get channel list.")));
    });
  };

  createNewChannel = (chatClient) => {
    return new Promise((resolve, reject) => {
      this.addMessage({ body: "Creating channel..." });
      chatClient
        .createChannel({
          uniqueName: this.props.chatChannel,
          friendlyName: "Chat",
        })
        .then(() => this.joinSelectedChannel(chatClient))
        .catch(() => reject(Error("Could not create channel.")));
    });
  };

  addMessage = (message) => {
    const messageData = {
      ...message,
      me: message.author === this.state.username,
    };
    this.setState({
      messages: [...this.state.messages, messageData],
    });
  };

  handleNewMessage = (text) => {
    if (this.state.channel) {
      this.state.channel.sendMessage(text);
    }
  };

  displayMemberNames = () => {
    var namesList = this.state.members.map(
      (member) => {
        return (
          <h1>{member.identity}</h1>
        )
      }
    )
    return namesList;
  }

  displayLocalMemberNames = () => {
    var namesList = this.state.localMemberArray.map(
      (member) => {
        return (
          <div className={circlesStyles.profileIconSlot} key={member._id} >
            <img src="/icons/tenByTen.png" style={{ height: "100%", position: "relative", opacity: 0 }} alt="Space" />
            <div style={{ backgroundColor: member.color }} className={circlesStyles.profilePictureSurround}>
              <Image
                cloudName="oijqec973f1nf"
                publicId={member.userProfilePictureID + ".png"}
                secure={true}
                className={circlesStyles.profileIcon}
              >
                <Transformation
                  height="100"
                  width="100"
                  gravity="face"
                  crop="fill"
                  radius="max"
                />
              </Image>
            </div>
          </div>
        )
      }
    )
    return namesList;
  }

  toggleCirclesVisible = () => {
    this.setState({ circlesVisible: !this.state.circlesVisible, newMessages: false })
  }


  render () {



    return (
      <div>
        <CSSTransition
          in={
            this.state.circlesVisible
          }
          timeout={1500}
          delay={0}
          classNames="slideFromRightCSSTransition"
          unmountOnExit
        >
          <div className={styles.circlesChatArea}>
            <div className={styles.semiOpaqueChatAreaBackground} />
            <div className={styles.chatWindow}>
              <div className={styles.circleTitle}>Live Union Circle</div>
              <div className={styles.spacer} />
              <MessageList messages={this.state.messages} />
              <div className={styles.spacer} />
              <MessageForm onMessageSend={this.handleNewMessage} />
            </div>
          </div>
        </CSSTransition>

        <div className={circlesStyles.circlesOpenCloseButtonArea}
          onClick={() => this.toggleCirclesVisible()}>
          <img className={circlesStyles.circlesOpenCloseIcons} src={window.$videoPath + "circles/circlesOpenCloseIcons.png"} alt="Open/Close" />
        </div>


        {/** Permanently visible version when hidden */}
        <CSSTransition
          in={
            !this.state.circlesVisible
          }
          timeout={1500}
          delay={0}
          classNames="slideFromRightCSSTransition"
          unmountOnExit
        >
          <div className={circlesStyles.circlesParticipantsOverlayArea}>
            <div className={circlesStyles.circlesBarParticipantsArea}>
            </div>
            <img className={circlesStyles.circlesBarLogo} src={window.$videoPath + "circles/circlesBarLogo.png"} alt="Circles bar logo" />
            <div className={circlesStyles.notificationOuterCircle}>
              <img src="/icons/tenByTen.png" style={{ height: "100%", position: "relative", opacity: 0 }} alt="Space" />
              {this.state.newMessages ?
                <div style={{ backgroundColor: "#8cc63f" }} className={circlesStyles.notificationInnerCircle}></div>
                :
                <div style={{ backgroundColor: "#e6e6e6" }} className={circlesStyles.notificationInnerCircle}></div>
              }
            </div>
          </div>
        </CSSTransition>

        <CSSTransition
          in={
            this.state.circlesVisible
          }
          timeout={1500}
          delay={0}
          classNames="slideFromRightCSSTransition"
          unmountOnExit
        >
          <div className={circlesStyles.circlesParticipantsOverlayArea}>


            <div className={circlesStyles.circlesBarParticipantsArea}>
              {this.displayLocalMemberNames()}
            </div>

            <img className={circlesStyles.circlesBarLogo} src={window.$videoPath + "circles/circlesBarLogo.png"} alt="Circles bar logo" />
            <div className={circlesStyles.notificationOuterCircle}>
              <img src="/icons/tenByTen.png" style={{ height: "100%", position: "relative", opacity: 0 }} alt="Space" />
              {this.state.newMessages ?
                <div style={{ backgroundColor: "#8cc63f" }} className={circlesStyles.notificationInnerCircle}></div>
                :
                <div style={{ backgroundColor: "#e6e6e6" }} className={circlesStyles.notificationInnerCircle}></div>
              }
            </div>
          </div>
        </CSSTransition>


      </div>
    );
  }
}

