import React, { Component } from "react";
import { CSSTransition } from "react-transition-group";
import { Link } from "react-router-dom";
import styles from "./Breakout.module.scss";

export default class Zoom extends Component {


  constructor() {
    super();
    this.state = {
      videoStateForwards: true,
      showContent: false,
      iFrameReloads: 0,
    };
  }


  componentDidMount () {
    this.viewPresentation();
  }


  componentDidUpdate () { }

  componentWillUnmount () {
    // Restore corner menu
    this.props.setCornerMenuStatus(1);
  }


  leaveZoom = () => {
    if (this.state.iFrameReloads === 1) {
      this.leavePresentation();
    }
    this.setState({ iFrameReloads: 1 })
  }


  viewPresentation = () => {
    if (this.breakoutVideo) {
      this.breakoutVideo.play();
    }
    // Set timeout in case video does not load/finish
    setTimeout(
      function () {
        this.forwardsVideoEnded();
      }.bind(this),
      3000
    );
  };

  leavePresentation = () => {
    this.setState({ showContent: false });
    if (this.reverseBreakoutVideo) {
      this.reverseBreakoutVideo.play();
    }
    // Set timeout in case video does not load/finish
    setTimeout(
      function () {
        this.reverseVideoEnded();
      }.bind(this),
      3000
    );
  };



  forwardsVideoEnded = () => {
    this.setState({ videoStateForwards: false, showContent: true });
    // Hide corner menu
    this.props.setCornerMenuStatus(3);
    // The iFrame is not a vimeo player in here
    //this.registerVimeoPlayListener();
  };

  reverseVideoEnded = () => {
    // Need to reset breakout video back to start before transitioning to it
    if (this.breakoutVideo) {
      this.breakoutVideo.currentTime = 0;
    }
    this.setState({ videoStateForwards: true, showContent: false });
  };

  render () {
    return (
      <div>
        <div className="imageNavDiv">
          {this.state.videoStateForwards ? (
            <div>
              <div
                className={styles.goToPresentation}
                onClick={this.viewPresentation}
              ></div>
              <Link to="/breakoutsStill">
                <img
                  className={`${styles.hoverHotspot} ${styles.exitHoverHotspot}`}
                  src="/hoverShapes/hoverSquare.png"
                  alt="Exit"
                ></img>
              </Link>
            </div>
          ) : null}
          {!this.state.videoStateForwards ? (
            <div
              className={styles.leavePresentation}
              onClick={this.leavePresentation}
            ></div>
          ) : null}

          <div>
            <video
              className="imageNavBackground"
              id="myVideo"
              loop={false}
              muted
              ref={(breakoutVideo) => (this.breakoutVideo = breakoutVideo)}
              onEnded={() => this.forwardsVideoEnded()}
            >
              <source
                src={window.$videoPath + "visuals/" + this.props.language + "/largeSeatedBreakout.mp4"}
                type="video/mp4"
              />
              <source
                src={window.$videoPath + "visuals/" + this.props.language + "/largeSeatedBreakout.webm"}
                type="video/webm"
              />
            </video>
            <CSSTransition
              in={!this.state.videoStateForwards}
              timeout={1500}
              classNames="imageNavBackground"
              unmountOnExit
            >
              <video
                className="imageNavBackground"
                id="myVideo"
                loop={false}
                muted
                ref={(reverseBreakoutVideo) =>
                  (this.reverseBreakoutVideo = reverseBreakoutVideo)
                }
                onEnded={() => this.reverseVideoEnded()}
              >
                <source
                  src={window.$videoPath + "visuals/" + this.props.language + "/largeSeatedBreakoutReverse.mp4"}
                  type="video/mp4"
                />
                <source
                  src={window.$videoPath + "visuals/" + this.props.language + "/largeSeatedBreakoutReverse.webm"}
                  type="video/webm"
                />
              </video>
            </CSSTransition>
          </div>

          <CSSTransition
            in={this.state.showContent && this.props.zoomMeetingID != null && this.props.zoomPassword != null && this.props.userProfile != null}
            timeout={1500}
            classNames="imageNavBackground"
            unmountOnExit
          >
            <div className={styles.largeBreakoutiFrameDiv}>
              {this.props.zoomMeetingID && this.props.zoomPassword && this.props.userProfile ?
                <iframe src={"/zoomCall.html?meetingID=" + this.props.zoomMeetingID + "&password=" + this.props.zoomPassword + "&username=" + this.props.userProfile.firstName}
                  id="contentIframe"
                  title="Content iFrame"
                  allow="camera;microphone"
                  className={styles.iFrameClass}
                  onLoad={this.leaveZoom} /> : null}

            </div>
          </CSSTransition>

        </div>
      </div>
    );
  }
}