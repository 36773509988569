import React, { Component } from "react";
import Modal from "react-modal";
import dompurify from "dompurify";
//import { Link } from "react-router-dom";
//import VideoPlayer from "./../modules/videoPlayer/VideoPlayer"
import { Image, Transformation } from "cloudinary-react";
import { Lightbox } from "react-modal-image";
import { requestPoints } from "./../helpers/requestPoints.js";
import ExhibitionStandQuestions from "./../questions/ExhibitionStandQuestions";
import styles from "./PosterModal.module.scss";

export default class PosterModal extends Component {
  constructor() {
    super();
    this.state = {
      showPrimaryTab: true,
      showPosterLightbox: false,
      posterVotedFor: false,
    };
  }

  posterVideoPlayed = () => {
    requestPoints("playPosterVideo", this.props.poster._id);
  };

  showPosterLightbox = () => {
    requestPoints("viewPosterGalleryPoster", this.props.poster._id);
    this.setState({ showPosterLightbox: true });
  };

  logOpenPDF = () => {
    requestPoints("viewPosterGalleryPosterPDF", this.props.poster._id);
  };

  upvotePoster = () => {
    this.setState({ posterVotedFor: true });
    console.log("Upvoting poster");
    // Submit action for points
    fetch("/api/upvotePoster", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        posterID: this.props.poster._id,
      }),
    })
      .then((result) => result.text())
      .then((response) => {
        console.log("Submitted poster upvote");
      });
    alert("Thank you for your vote!");
  };

  sanitizeCustomField = (customField) => {
    return { __html: dompurify.sanitize(customField) };
  };

  onOpenModal = () => {
    this.setState({
      posterVotedFor: false,
      showPrimaryTab: true
    });
  };

  render () {
    return (
      <Modal
        onAfterOpen={this.onOpenModal}
        isOpen={this.props.showPosterModal}
        ariaHideApp={false}
        onRequestClose={this.props.closePosterModal}
        contentLabel="Poster Modal"
        overlayClassName={styles.modalOverlay}
        className={styles.modalContent}
        style={{ content: { backgroundImage: "url(" + window.$videoPath + "cornerNav/modalBackground.png)" } }}
      >
        <div className={styles.modalFlexboxColumn}>
          <div className={styles.modalTitleBar} style={{ backgroundImage: 'url(' + window.$videoPath + 'cornerNav/modalHeader.png)', }}>
            <h1 className={styles.modalPageTitle}>
              Poster Gallery
              </h1>
              <button
                                className={styles.modalClose}
                                variant="success"
                                onClick={this.props.closePosterModal}
                                aria-label="Close"
                            >
                                Close
                                <img
                                    className={styles.modalCloseIcon}
                                    src="/icons/close-no-circle.png"
                                    alt="Close"
                                />
                            </button>




              {/**<Button
                className={styles.tabSelectionButtonQuestion}
                variant="success"
                onClick={() => this.setState({ showPrimaryTab: false })}
              >
                Ask a question
              </Button>
              <Button
                className={styles.tabSelectionButtonPoster}
                variant="success"
                onClick={() => this.setState({ showPrimaryTab: true })}
              >
                Overview
              </Button>
              {this.props.poster &&
                this.props.poster.associatedPosterSessionID ? (
                <Button
                  className={styles.tabSelectionButtonRoundtable}
                  variant="danger"
                  onClick={() => alert("Each poster can be linked to it's own roundtable discussion. Roundtables take place on-platform as a group video call, keeping attendees within your branded environment.")}
                >
                  Roundtable
                </Button>
                <Link
                  to={"/roundtableSession/" + this.props.poster.associatedPosterSessionID + "/posters/na"}
                >
                  <Button
                    className={styles.tabSelectionButtonRoundtable}
                    variant="danger"
                  >
                    Roundtable
                  </Button>
                </Link>
              ) : null}
              {this.state.posterVotedFor ? (
                <img
                  alt="Upvote"
                  src={window.$videoPath + "icons/expertiseYellow.png"}
                  className={styles.tabSelectionButtonPlusOne}
                  variant="primary"
                />
              ) : (
                <img
                  alt="Upvote"
                  src={window.$videoPath + "icons/expertise.png"}
                  className={styles.tabSelectionButtonPlusOne}
                  variant="primary"
                  onClick={this.upvotePoster}
                />
              )}
            */}
          </div>

          <div className={styles.scrollableArea}>
            {this.state.showPrimaryTab ? (
              <div className={styles.columnContainer}>
                <div className={styles.formColumn}>
                  {this.props.poster &&
                    this.props.poster.posterVideo != null ? (
                    <div>
                      <Image
                        cloudName="oijqec973f1nf"
                        publicId={
                          this.props.poster.posterVideo
                        }
                        secure={true}
                        className={styles.heroImage}
                      >
                        <Transformation
                          height="450"
                          width="250"
                        />
                      </Image>
                      {/**<div className={styles.posterVideoDiv}>
                        <VideoPlayer
                          resourceLocator={this.props.poster.posterVideo}
                          location={"poster"}
                          userProfile={this.props.userProfile}
                          autoplay={true}
                        />
                      </div>*/}
                    </div>

                  ) : null}
                  <div style={{ marginTop: 20 }}>
                    <h2 className={styles.posterNameText}>
                      {/**<strong>Title: </strong>*/}
                      {this.props.poster && this.props.poster.posterName}
                    </h2>
                    {/**<h2 className={styles.posterPresenterNameText}>
                      <strong>Presenter: </strong>
                      {this.props.poster &&
                        this.props.poster.posterPresenterName}
                    </h2>*/}
                  </div>
                  <div className={styles.aboutTextDiv}>
                    <h2 dangerouslySetInnerHTML={this.sanitizeCustomField(this.props.poster && this.props.poster.aboutText)} className={styles.aboutText}>
                    </h2>
                  </div>
                </div>
                <div className={styles.formColumn}>
                  <h5 className={styles.votingInfo}>
                    Please use the star button above to vote for your favourite
                    posters. ‘Best Poster’ will be announced in the closing
                    remarks
                  </h5>
                  <img
                    alt="Poster preview"
                    className={styles.posterPreview}
                    src={
                      this.props.poster &&
                      window.$videoPath +
                      "postersSmallJPEG/" +
                      this.props.poster.posterFilename +
                      ".jpg"
                    }
                    onClick={this.showPosterLightbox}
                  />
                  {this.state.showPosterLightbox && (
                    <Lightbox
                      onClose={() =>
                        this.setState({ showPosterLightbox: false })
                      }
                      className={styles.posterPreview}
                      large={
                        this.props.poster &&
                        window.$videoPath +
                        "postersLargeJPEG/" +
                        this.props.poster.posterFilename +
                        ".jpg"
                      }
                      alt={this.props.poster && this.props.poster.posterName}
                    />
                  )}
                  <br />
                  {this.props.poster ? (
                    <h3 className={styles.enlargeHint}>
                      Click poster to enlarge
                      <br />
                      or open PDF{" "}
                      <a
                        href={
                          window.$videoPath +
                          "postersPDF/" +
                          this.props.poster.posterFilename +
                          ".pdf"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={this.logOpenPDF}
                      >
                        here
                      </a>
                    </h3>
                  ) : null}
                </div>
              </div>
            ) : (
              <div>
                <ExhibitionStandQuestions
                  exhibitionStandID={this.props.poster && this.props.poster._id}
                />
              </div>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}
