import React, { Component } from "react";
import { Link } from "react-router-dom";
import ExhibitionHallMobile from "./ExhibitionHallMobile"
import { requestPoints } from "../helpers/requestPoints.js";
import styles from "./ExhibitionHall.module.scss";

export default class ExhibitionHall extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount () {
    requestPoints("visitExhibition", "hall:" + this.props.match.params.hallID);
  }

  render () {
    return (
      <div>
        {this.props.showMobile ?
          <ExhibitionHallMobile
            hallID={this.props.match.params.hallID}
          />
          :
          <div className={styles.masterPageDiv}>

            {this.props.match.params.hallID === "1" ?
              <div>
                <img
                  className="imageNavBackground"
                  src={window.$videoPath + "exhibition/fullScreenVisuals/" + this.props.language + "/hall1.jpg"}
                  alt="Background"
                ></img>

                <Link to="/exhibitionHall/2">
                  <img
                    className={`${styles.hoverHotspot} ${styles.previousHallHotspot}`}
                    src="/hoverShapes/hoverSquare.png"
                    alt="Navigate to Hall"
                  ></img>
                </Link>

                <Link to="/exhibitionHall/2">
                  <img
                    className={`${styles.hoverHotspot} ${styles.nextHallHotspot}`}
                    src="/hoverShapes/hoverSquare.png"
                    alt="Navigate to Hall"
                  ></img>
                </Link>

                <Link to="/exhibitionStand/601db92f87891434a8d1bee9">
                  <img
                    className={`${styles.hoverHotspot} ${styles.stand1Of9Hotspot}`}
                    src="/hoverShapes/hoverSquare.png"
                    alt="Navigate to Stand"
                  ></img>
                </Link>

                <Link to="/exhibitionStand/601db92f87891434a8d1bee6">
                  <img
                    className={`${styles.hoverHotspot} ${styles.stand2Of9Hotspot}`}
                    src="/hoverShapes/hoverSquare.png"
                    alt="Navigate to Stand"
                  ></img>
                </Link>

                <Link to="/exhibitionStand/601db92f87891434a8d1beeb">
                  <img
                    className={`${styles.hoverHotspot} ${styles.stand3Of9Hotspot}`}
                    src="/hoverShapes/hoverSquare.png"
                    alt="Navigate to Stand"
                  ></img>
                </Link>

                <Link to="/exhibitionStand/601db92f87891434a8d1bee7">
                  <img
                    className={`${styles.hoverHotspot} ${styles.stand4Of9Hotspot}`}
                    src="/hoverShapes/hoverSquare.png"
                    alt="Navigate to Stand"
                  ></img>
                </Link>

                <Link to="/exhibitionStand/601db92f87891434a8d1bee8">
                  <img
                    className={`${styles.hoverHotspot} ${styles.stand5Of9Hotspot}`}
                    src="/hoverShapes/hoverSquare.png"
                    alt="Navigate to Stand"
                  ></img>
                </Link>

                <Link to="/exhibitionStand/601db92f87891434a8d1bee5">
                  <img
                    className={`${styles.hoverHotspot} ${styles.stand6Of9Hotspot}`}
                    src="/hoverShapes/hoverSquare.png"
                    alt="Navigate to Stand"
                  ></img>
                </Link>

                <Link to="/exhibitionStand/601db92f87891434a8d1beea">
                  <img
                    className={`${styles.hoverHotspot} ${styles.stand7Of9Hotspot}`}
                    src="/hoverShapes/hoverSquare.png"
                    alt="Navigate to Stand"
                  ></img>
                </Link>

                <Link to="/exhibitionStand/601db92f87891434a8d1bee4">
                  <img
                    className={`${styles.hoverHotspot} ${styles.stand8Of9Hotspot}`}
                    src="/hoverShapes/hoverSquare.png"
                    alt="Navigate to Stand"
                  ></img>
                </Link>

                <Link to="/exhibitionSearch">
                  <img
                    className={`${styles.hoverHotspot} ${styles.searchStandOf9Hotspot}`}
                    src="/hoverShapes/hoverSquare.png"
                    alt="Navigate to Search"
                  ></img>
                </Link>

              </div> : null}

            {this.props.match.params.hallID === "2" ?
              <div>
                <img
                  className="imageNavBackground"
                  src={window.$videoPath + "exhibition/fullScreenVisuals/" + this.props.language + "/hall2.jpg"}
                  alt="Background"
                ></img>

                <Link to="/exhibitionHall/1">
                  <img
                    className={`${styles.hoverHotspot} ${styles.previousHallHotspot}`}
                    src="/hoverShapes/hoverSquare.png"
                    alt="Navigate to Hall"
                  ></img>
                </Link>

                <Link to="/exhibitionHall/1">
                  <img
                    className={`${styles.hoverHotspot} ${styles.nextHallHotspot}`}
                    src="/hoverShapes/hoverSquare.png"
                    alt="Navigate to Hall"
                  ></img>
                </Link>

              </div> : null}


            <Link to="/exhibition">
              <img
                className={`${styles.hoverHotspot} ${styles.lobbyHoverHotspot}`}
                src="/hoverShapes/hoverSquare.png"
                alt="Navigate to Lobby"
              ></img>
            </Link>


          </div>
        }
      </div>
    );
  }
}
