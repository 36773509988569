import React, { Component } from "react";
import ReactPlayer from 'react-player';
import mux from "mux-embed";
import Vimeo from "@u-wave/react-vimeo";
import { requestPoints } from "./../../helpers/requestPoints.js";

export default class VideoPlayer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            muxSigningToken: "",
            resourceType: "",
            resourceID: "",
            showControls: true,
            muted: false,
        };
        this.videoPlayer = React.createRef();
    }

    componentDidMount () {
        this.determineSourceType();
        if (this.props.showControls === false) {
            this.setState({ showControls: false })
        }
        if (this.props.muted === true) {
            this.setState({ muted: true })
        }
    }

    // Test whether resource locator has changed
    componentDidUpdate (prevProps, prevState) {
        if (prevProps.resourceLocator !== this.props.resourceLocator) {
            this.determineSourceType();
        }
    }

    determineSourceType = () => {
        const resourceType = this.props.resourceLocator.substr(0, this.props.resourceLocator.indexOf('://'));
        const resourceID = this.props.resourceLocator.substr(this.props.resourceLocator.indexOf('://') + 3, this.props.resourceLocator.length)

        // If the new content is a mux: URL then get a mux signing token
        if (resourceType === "mux") {
            this.getMuxToken(resourceID)
        } else {
            this.setState({
                resourceType: resourceType, resourceID: resourceID
            });
        }
    }


    getMuxToken = (resourceID) => {
        fetch("/api/getMuxToken?playbackID=" + resourceID)
            .then((res) => res.json())
            .then((data) => {
                console.log("Mux token for " + resourceID + "is: " + data.tokenValue)
                this.setState({
                    resourceType: "mux", resourceID: resourceID, muxSigningToken: data.tokenValue
                });
            });
    }

    monitorMux = () => {
        if (this.videoPlayer.current) {
            const initTime = Date.now();
            mux.monitor(this.videoPlayer.current.getInternalPlayer(), {
                debug: true,
                // New line below to capture reference back to Mux asset being played
                hlsjs: this.videoPlayer.current.getInternalPlayer('hls'),
                // 
                data: {
                    env_key: 'epbgm0stfomfn3iu5fjntrbd0', // required
                    // Metadata fields
                    player_name: 'Location: ' + this.props.location, // any arbitrary string you want to use to identify this player
                    player_init_time: initTime,
                    viewer_user_id: this.props.userProfile._id,
                    video_title: this.props.title,
                    video_id: this.props.videoID,
                    // ...
                }
            });
        } else {
            console.log("Video player not current")
        }
    }

    logPlayback = () => {
        switch (this.props.location) {
            case "plenary":
                requestPoints("playPlenaryVideo", this.props.resourceLocator);
                break;
            case "breakout":
                requestPoints("playBreakoutVideo", this.props.resourceLocator);
                break;
            case "onDemand":
                requestPoints("playOnDemandVideo", this.props.resourceLocator);
                break;
            case "poster":
                requestPoints("playPosterVideo", this.props.resourceLocator);
                break;
            case "exhibitionMain":
                requestPoints("viewExhibitionMainVideo", this.props.resourceLocator);
                break;
            case "exhibitionResource":
                requestPoints("viewExhibitionResourceVideo", this.props.resourceLocator);
                break;
            case "break":
                requestPoints("viewTakeABreakVideo", this.props.resourceLocator);
                break;
            default:
                requestPoints("playVideo", this.props.resourceLocator);
                break;
        }
    }

    render () {
        return (
            <React.Fragment >
                {this.state.resourceType === "mux" && this.props.userProfile && this.state.muxSigningToken && this.state.muxSigningToken.length > 0 ?
                    <ReactPlayer
                        onReady={() => this.monitorMux()}
                        onPlay={() => this.logPlayback()}
                        webkit-playsinline="true"
                        playsInline
                        playsinline={true}
                        ref={this.videoPlayer}
                        playing={this.props.autoPlay}
                        controls={this.state.showControls}
                        height={"100%"}
                        width={"100%"}
                        muted={this.state.muted}
                        onEnded={this.props.onEnded}
                        url={"https://stream.mux.com/" + this.state.resourceID + ".m3u8?token=" + this.state.muxSigningToken} />
                    : null}
                {this.state.resourceType === "https" ?
                    <iframe
                        title="Content iFrame"
                        style={{ height: "100%", width: "100%", border: "0px" }}
                        autoPlay
                        allow="autoplay; fullscreen"
                        allowFullScreen
                        src={"https://" + this.state.resourceID}
                    ></iframe>
                    : null}
                {this.state.resourceType === "vimeo" ?
                    <Vimeo
                        style={{ height: "100%", width: "100%", border: "0px" }}
                        controls={true}
                        video={this.state.resourceID}
                        autoPlay={this.props.autoPlay}
                        responsive={true}
                        allow="autoplay; fullscreen"
                        allowfullscreen
                        mozallowfullscreen
                        webkitallowfullscreen
                        onPlay={() => this.logPlayback()}
                    ></Vimeo>
                    : null
                }
            </React.Fragment>
        );
    }
}