import React, { Component } from "react";
import { Pannellum, PannellumVideo } from "pannellum-react";
import myImage from "./warehouse360.jpg";
import styles from "./Lobby360.module.scss";

export default class Lobby extends Component {


  render () {
    return (
      <div className={styles.masterPageDiv}>
        <div className={styles.viewerArea}>
          <Pannellum
            width="100%"
            height="100%"
            image={myImage}
            pitch={10}
            yaw={0}
            hfov={110}
            autoLoad
            onLoad={() => {
              console.log("panorama loaded");
            }}
          ></Pannellum>
        </div>
      </div>
    );
  }
}
