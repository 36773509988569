import React, { Component } from "react";
import { Trans } from 'react-i18next';
import { Link } from "react-router-dom";
import { Image, Transformation } from "cloudinary-react";
import GalleryModal from "./GalleryModal.js";
import styles from "./Gallery.module.scss";

export default class Gallery extends Component {
  constructor() {
    super();
    //Set default message
    this.state = {
      galleryContent: [],
      searchString: "",
      showMeetingRequestModal: false,
      meetingRequesteeUser: null,
      meetingRequesteeUserMeetings: [],
      meetingNote: "",
      meetingSlots: [],
      selectedMeetingSlot: null,
      existingUserMeetings: [],
      visualGallery: false,
    };
    this.requestMeeting = this.requestMeeting.bind(this);
  }

  componentDidMount() {
    // Hide corner menu
    this.props.setCornerMenuStatus(3);

    if (this.props.visualGallery) {
      this.setState({ visualGallery: true })
    }
    this.loadCurrentDatabaseContent();
  }

  componentWillUnmount() {
    // Set corner menu to displayed again
    this.props.setCornerMenuStatus(1);
  }

  componentDidUpdate() {
    // Double check corner menu status is correct
    if (this.props.cornerMenuStatus !== 3) {
      console.log(this.props.cornerMenuStatus);
      this.props.setCornerMenuStatus(3);
    }
  }

  loadCurrentDatabaseContent = () => {
    fetch("/api/getUserGalleryContent")
      .then((res) => res.json())
      .then((data) => this.setState({ galleryContent: data }));
    // Get meeting slots
    fetch("/api/getMeetingSlots")
      .then((res) => res.json())
      .then((data) => this.setState({ meetingSlots: data }));
    // Get taken meeting slots for signed in user
    fetch("/api/getUserMeetings")
      .then((res) => res.json())
      .then((data) => this.setState({ existingUserMeetings: data }));
  }

  getRequesteeAvailability(userID) {
    fetch("/api/getRequesteeUserMeetings?requesteeUserID=" + userID)
      .then((res) => res.json())
      .then((data) => this.setState({ meetingRequesteeUserMeetings: data }));
  }

  // Called by updates to search box
  handleChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleMeetingRequestClose = () => {
    this.loadCurrentDatabaseContent();
    this.setState({ showMeetingRequestModal: false });
  };

  // Called when user clicks request meeting button on another's profile
  requestMeeting = (user) => {
    this.loadCurrentDatabaseContent();
    this.getRequesteeAvailability(user._id);
    this.setState({
      meetingRequesteeUser: user,
      meetingNote: "",
      showMeetingRequestModal: true,
      selectedMeetingSlot: this.state.meetingSlots[0]._id,
    });
    console.log("Requesting meeting with " + user.firstName);
  };

  render() {
    const userGalleryFiltered = this.state.galleryContent.filter(
      (user) =>
        user.firstName
          .toLowerCase()
          .includes(this.state.searchString.toLowerCase()) ||
        user.surname
          .toLowerCase()
          .includes(this.state.searchString.toLowerCase()) ||
        user.jobTitle
          .toLowerCase()
          .includes(this.state.searchString.toLowerCase()) ||
        user.country
          .toLowerCase()
          .includes(this.state.searchString.toLowerCase()) ||
        user.company
          .toLowerCase()
          .includes(this.state.searchString.toLowerCase()) ||
        user.areasOfInterest
          .toLowerCase()
          .includes(this.state.searchString.toLowerCase())
    );

    const userGallery = userGalleryFiltered.map((user) => {
      return (
        <div
          key={user._id}
          className={styles.userContainer}
          onClick={() => this.requestMeeting(user)}
        >
          <div className={styles.profilePictureArea}>
            <img
              className={styles.profilePictureBackground}
              src={window.$videoPath + "icons/profilePictureBackground.png"}
              alt="Detail"
            />
            <Image
              className={styles.profilePicture}
              cloudName="oijqec973f1nf"
              publicId={user.userProfilePictureID + ".png"}
              width="180"
              height="180"
              secure={true}
            >
              <Transformation
                height="180"
                width="180"
                gravity="face"
                crop="fill"
                radius="max"
              />
            </Image>
          </div>
          <div className={styles.userNameContainer}>
            <p className={styles.userNameText}>
              {user.firstName} {user.surname}
            </p>
          </div>
          <img
            className={styles.detailButton}
            src={window.$videoPath + "icons/galleryInfo.png"}
            alt="Detail"
          />
        </div>
      );
    });

    return (
      <div>
        <GalleryModal
          showMeetingRequestModal={this.state.showMeetingRequestModal}
          handleMeetingRequestClose={this.handleMeetingRequestClose}
          meetingRequesteeUser={this.state.meetingRequesteeUser}
          meetingSlots={this.state.meetingSlots}
          existingUserMeetings={this.state.existingUserMeetings}
          meetingRequesteeUserMeetings={this.state.meetingRequesteeUserMeetings}
          userProfile={this.props.userProfile}
          loadCurrentDatabaseContent={this.loadCurrentDatabaseContent}
        />

        {this.state.visualGallery ?
          <>
            <div className="imageNavDiv" >
              <img
                className="imageNavBackground"
                src={window.$videoPath + "demoVenues/warehouse/altNetworking4Gallery.jpg"}
                alt="Background"
              ></img>

              <div className={styles.searchTitleAreaVisual}>
                <p>
                  Search for and chat with people in similar job
                  roles, locations or with shared interests
                </p>
                <input
                  className={styles.searchBox}
                  type="text"
                  name="searchString"
                  placeholder="Search"
                  value={this.state.searchString}
                  onChange={this.handleChange}
                  style={{ backgroundImage: 'url(' + window.$videoPath + 'icons/searchIcon.png)', backgroundImageWidth: '100%' }}
                />
              </div>

              <section className={styles.profileSegmentsAreaVisual}>{userGallery}</section>
            </div>
          </>
          :
          <>
            <div className={styles.galleryBackgroundDiv}>
              {/**<img
            className="imageNavBackground"
            src={window.$videoPath + "background.png"}
            alt="Background"
          ></img>*/}
            </div>

            <div className={styles.tabBar}>
              <Link to={"/lobby"} className={styles.backLink}>
                <div className={styles.tabBackDiv}>
                  <img
                    className={styles.backArrow}
                    src={window.$videoPath + "icons/backArrow.png"}
                    alt="Back"
                  />
                  Back to Lobby
                </div>
              </Link>
            </div>

            <div className={styles.titleAndSearchBar}>
              <h1 className={styles.pageTitle}>
                <strong><Trans>attendees</Trans></strong>
              </h1>

              <div className={styles.searchTitleArea}>
                <p className={styles.pageInstruction}>Search for and chat with people in similar job roles, locations or with shared interests
                </p>

              </div>
              <input
                className={styles.searchBox}
                type="text"
                name="searchString"
                placeholder="Search"
                value={this.state.searchString}
                onChange={this.handleChange}
                style={{ backgroundImage: 'url(' + window.$videoPath + 'icons/searchIcon.png)', backgroundImageWidth: '100%' }}
              />
            </div>

            <section className={styles.profileSegmentsArea}>{userGallery}</section>
          </>}
      </div>
    );
  }
}
