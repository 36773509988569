import React, { Component } from "react";
import dompurify from "dompurify";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import Vimeo from "@u-wave/react-vimeo";
import ChatFunction from "./../twilioChat/ChatFunction";
import { Image, Transformation } from "cloudinary-react";
import ExhibitionStandMobile from "./ExhibitionStandMobile"
import ExhibitionResourceArea from "./ExhibitionResourceArea"
import ExhibitionSubmitDetails from "./ExhibitionSubmitDetails"
import ExhibitionStandQuestions from "./../questions/ExhibitionStandQuestions";
import { requestPoints } from "../helpers/requestPoints.js";
import styles from "./ExhibitionStand.module.scss";

export default class ExhibitionStand extends Component {
  constructor() {
    super();
    //Set default message
    this.state = {
      standName: "",
      hallNumber: 1,
      standSequenceNumber: 0,
      panelColor: "",
      panelTextColor: "",
      deskFrontColor: "",
      logoAssetID: "",
      backPanelAssetID: "",
      deskPanelAssetID: "",

      showResourceArea: false,
      showQAndAArea: false,
      showTextChatArea: false,
      showVideoChatArea: false,
      showLeaveDetailsArea: false,
      bookOneToOneGuid: false,
      // Used to store ref to Whereby Room
      videoChatResourceID: "",

      mainVideoID: "",
      resourceVideo1ID: "",
      resourceVideo2ID: "",
      resourceLink1: "",
      resourceLink1Type: 1,
      resourceLink2: "",
      resourceLink2Type: 1,
      resourceLink3: "",
      resourceLink3Type: 1,
      resourceLink4: "",
      resourceLink4Type: 1,
      resourceLink5: "",
      resourceLink5Type: 1,
      resourceLink6: "",
      resourceLink6Type: 1,
      resourceLink7: "",
      resourceLink7Type: 1,
      resourceLink8: "",
      resourceLink8Type: 1,

      nextStandID: null,
      previousStandID: null,

      showResourceAreaModal: false,
      showQuestionsModal: false,
      showTextChatModal: false,
      showLeaveDetailsAreaModal: false,
    };
  }

  componentDidMount () {
    // Get selected stand ID from React Router path
    fetch(
      "/api/getExhibitionStand?exhibitionStandID=" +
      this.props.match.params.exhibitionStandID
    )
      // Need to somehow request by received prop ID
      .then((res) => res.json())
      .then((data) => this.loadResponseIntoState(data));
  }

  loadResponseIntoState (data) {
    if (data.exhibitionStand != null) {
      this.setState({
        standName: data.exhibitionStand.standName,
        hallNumber: data.exhibitionStand.hallNumber,
        standSequenceNumber: data.exhibitionStand.standSequenceNumber,
        panelColor: data.exhibitionStand.panelColor,
        panelTextColor: data.exhibitionStand.panelTextColor,
        deskFrontColor: data.exhibitionStand.deskFrontColor,
        logoAssetID: data.exhibitionStand.logoAssetID,
        backPanelAssetID: data.exhibitionStand.backPanelAssetID,
        deskPanelAssetID: data.exhibitionStand.deskPanelAssetID,

        showResourceArea: data.exhibitionStand.showResourceArea,
        showQAndAArea: data.exhibitionStand.showQAndAArea,
        showTextChatArea: data.exhibitionStand.showTextChatArea,
        showVideoChatArea: data.exhibitionStand.showVideoChatArea,
        showLeaveDetailsArea: data.exhibitionStand.showLeaveDetailsArea,
        bookOneToOneGuid: data.exhibitionStand.showbookOneToOneGuid,
        videoChatResourceID: data.exhibitionStand.videoChatResourceID,

        mainVideoID: data.exhibitionStand.mainVideoID,
        resourceVideo1ID: data.exhibitionStand.resourceVideo1ID,
        resourceVideo2ID: data.exhibitionStand.resourceVideo2ID,
        resourceLink1Title: data.exhibitionStand.resourceLink1Title,
        resourceLink1: data.exhibitionStand.resourceLink1,
        resourceLink1Type: data.exhibitionStand.resourceLink1Type,
        resourceLink2Title: data.exhibitionStand.resourceLink2Title,
        resourceLink2: data.exhibitionStand.resourceLink2,
        resourceLink2Type: data.exhibitionStand.resourceLink2Type,
        resourceLink3Title: data.exhibitionStand.resourceLink3Title,
        resourceLink3: data.exhibitionStand.resourceLink3,
        resourceLink3Type: data.exhibitionStand.resourceLink3Type,
        resourceLink4Title: data.exhibitionStand.resourceLink4Title,
        resourceLink4: data.exhibitionStand.resourceLink4,
        resourceLink4Type: data.exhibitionStand.resourceLink4Type,
        resourceLink5Title: data.exhibitionStand.resourceLink5Title,
        resourceLink5: data.exhibitionStand.resourceLink5,
        resourceLink5Type: data.exhibitionStand.resourceLink5Type,
        resourceLink6Title: data.exhibitionStand.resourceLink6Title,
        resourceLink6: data.exhibitionStand.resourceLink6,
        resourceLink6Type: data.exhibitionStand.resourceLink6Type,
        resourceLink7Title: data.exhibitionStand.resourceLink7Title,
        resourceLink7: data.exhibitionStand.resourceLink7,
        resourceLink7Type: data.exhibitionStand.resourceLink7Type,
        resourceLink8Title: data.exhibitionStand.resourceLink8Title,
        resourceLink8: data.exhibitionStand.resourceLink8,
        resourceLink8Type: data.exhibitionStand.resourceLink8Type,

        nextStandID: data.exhibitionStand.nextStandID,
        previousStandID: data.exhibitionStand.previousStandID,
      });
    }
  }

  sanitizeCustomField = (customField) => {
    return { __html: dompurify.sanitize(customField) };
  };


  setShowResourceAreaModal = (showResourceAreaModal) => {
    this.setState({ showResourceAreaModal: showResourceAreaModal });
  };

  setShowQuestionsModal = (showQuestionsModal) => {
    this.setState({ showQuestionsModal: showQuestionsModal });
  };

  setShowTextChatModal = (showTextChatModal) => {
    this.setState({ showTextChatModal: showTextChatModal });
  };

  setShowLeaveDetailsAreaModal = (showLeaveDetailsAreaModal) => {
    this.setState({ showLeaveDetailsAreaModal: showLeaveDetailsAreaModal });
  };

  render () {
    // Sets background colour for all panels
    let standThemeColorStyle = { backgroundColor: this.state.panelColor }
    let panelTextColorStyle = { color: this.state.panelTextColor }
    let deskFrontColorStyle = { backgroundColor: this.state.deskFrontColor }
    return (
      <div>

        <Modal
          isOpen={this.state.showResourceAreaModal}
          ariaHideApp={false}
          onRequestClose={() => this.setShowResourceAreaModal(false)}
          contentLabel="Resource Area Modal"
          overlayClassName={styles.modalOverlay}
          className={styles.modalContent}
          style={{ content: { backgroundImage: "url(" + window.$videoPath + "cornerNav/modalBackground.png)" } }}
        >
          <div className={styles.modalFlexboxColumn}>
            <div className={styles.resourceAreaTitleBar}>
              <Image
                className={styles.resourceAreaCompanyLogo}
                cloudName="beehive35j35jq"
                publicId={this.state.logoAssetID}
                secure={true}
              >
                <Transformation
                  height="50"
                  width="250"
                  crop="pad"
                  gravity="west"
                />
              </Image>
              <h1 className={styles.pageTitle}>{" "}</h1>
              <button
                className={styles.modalCloseDark}
                variant="success"
                onClick={() => this.setShowResourceAreaModal(false)}
              >
                Close
                <img
                  className={styles.closeIcon}
                  src="/icons/close-no-circle-grey.png"
                  alt="Close"
                />
              </button>
            </div>

            <div className={styles.scrollableArea}>
              <ExhibitionResourceArea {...this.state} />
            </div>
          </div>
        </Modal>


        <Modal
          isOpen={this.state.showQuestionsModal}
          ariaHideApp={false}
          onRequestClose={() => this.setShowQuestionsModal(false)}
          contentLabel="Questions Modal"
          overlayClassName={styles.modalOverlay}
          className={styles.modalContent}
          style={{ content: { backgroundImage: "url(" + window.$videoPath + "cornerNav/modalBackground.png)" } }}
        >
          <div className={styles.modalFlexboxColumn}>
            <div className={styles.exhibitionTitleBar} style={{ backgroundImage: 'url(' + window.$videoPath + 'cornerNav/modalHeader.png)', }} >
              <h1 className={styles.exhibitionPageTitle}>Q&A</h1>
              <button
                className={styles.modalClose}
                variant="success"
                onClick={() => this.setShowQuestionsModal(false)}
              >
                Close
                <img
                  className={styles.closeIcon}
                  src="/icons/close-no-circle.png"
                  alt="Close"
                />
              </button>
            </div>

            <div className={styles.scrollableArea}>
              <ExhibitionStandQuestions
                exhibitionStandID={this.props.match.params.exhibitionStandID}
                featureColor={this.state.panelColor}
              />
            </div>
          </div>
        </Modal>

        <Modal
          isOpen={this.state.showTextChatModal}
          ariaHideApp={false}
          onRequestClose={() => this.setShowTextChatModal(false)}
          contentLabel="Text Chat Modal"
          overlayClassName={styles.modalOverlay}
          className={styles.modalContent}
          style={{ content: { backgroundImage: "url(" + window.$videoPath + "cornerNav/modalBackground.png)" } }}
        >
          <div className={styles.modalFlexboxColumn}>
            <div className={styles.exhibitionTitleBar} style={{ backgroundImage: 'url(' + window.$videoPath + 'cornerNav/modalHeader.png)', }}>
              <h1 className={styles.exhibitionPageTitle}>Text Chat</h1>
              <button
                className={styles.modalClose}
                variant="success"
                onClick={() => this.setShowTextChatModal(false)}
              >
                Close
                <img
                  className={styles.closeIcon}
                  src="/icons/close-no-circle.png"
                  alt="Close"
                />
              </button>
            </div>

            <div className={styles.scrollableArea}>
              <div className={styles.chatFunctionContainer}>
                <ChatFunction
                  userProfile={this.props.userProfile}
                  chatChannel={this.props.match.params.exhibitionStandID}
                />
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          isOpen={this.state.showLeaveDetailsAreaModal}
          ariaHideApp={false}
          onRequestClose={() => this.setShowLeaveDetailsAreaModal(false)}
          contentLabel="Leave Details Modal"
          overlayClassName={styles.modalOverlay}
          className={styles.modalContent}
          style={{ content: { backgroundImage: "url(" + window.$videoPath + "cornerNav/modalBackground.png)" } }}
        >
          <div className={styles.modalFlexboxColumn}>
            <div className={styles.exhibitionTitleBar}>
              <h1 className={styles.exhibitionPageTitle}>Leave Details</h1>
              <button
                className={styles.modalClose}
                variant="success"
                onClick={() => this.setShowLeaveDetailsAreaModal(false)}
              >
                Close
                <img
                  className={styles.closeIcon}
                  src="/icons/close-no-circle.png"
                  alt="Close"
                />
              </button>
            </div>

            <div className={styles.scrollableArea}>
              <ExhibitionSubmitDetails exhibitionStandID={this.props.match.params.exhibitionStandID} />
            </div>
          </div>
        </Modal>


        {
          this.props.showMobile ?
            <ExhibitionStandMobile
              setShowResourceAreaModal={this.setShowResourceAreaModal}
              setShowQuestionsModal={this.setShowQuestionsModal}
              setShowTextChatModal={this.setShowTextChatModal}
              setShowLeaveDetailsAreaModal={this.setShowLeaveDetailsAreaModal}
              exhibitionStandID={this.props.match.params.exhibitionStandID}
              {...this.state}
            />
            :
            <div className={styles.masterPageDiv}>
              <img
                className="imageNavBackground"
                src={window.$videoPath + "exhibition/fullScreenVisuals/" + this.props.language + "/exhibitionStand.jpg"}
                alt="Background"
              ></img>

              {/** Video Screens */}
              {
                this.state.mainVideoID ?
                  <div className={styles.videoScreen1Frame}>
                    <Vimeo
                      className={styles.videoScreen1}
                      controls={true}
                      video={this.state.mainVideoID}
                      responsive={true}
                      allow="autoplay; fullscreen"
                      allowfullscreen
                      mozallowfullscreen
                      webkitallowfullscreen
                      onPlay={() => requestPoints(
                        "viewExhibitionMainVideo",
                        this.props.match.params.exhibitionStandID
                      )}
                    ></Vimeo>
                  </div> : null
              }
              {/** Physical Elements */}
              {this.state.backPanelAssetID ? <img className={styles.backPanel} src={window.$videoPath + "exhibition/backPanels/" + this.state.backPanelAssetID} alt="Back panel" /> : null}

              <div style={standThemeColorStyle} className={styles.screenPanel} ></div>
              <img className={styles.screenPanelShadow} src={window.$videoPath + "exhibition/standLeftPanelShadow.png"} alt="Screen panel" />

              {/** Either display custom desk front or stock colour */}
              {this.state.deskPanelAssetID ? <img className={styles.deskPanelGraphic} src={window.$videoPath + "exhibition/deskFronts/" + this.state.deskPanelAssetID} alt="Desk panel" />
                : <div style={deskFrontColorStyle} className={styles.deskPanel}>
                  <Image
                    className={styles.deskFrontLogo}
                    cloudName="beehive35j35jq"
                    publicId={this.state.logoAssetID}
                    secure={true}
                  >
                    <Transformation
                      width="500"
                      height="130"
                      crop="pad"
                    />
                  </Image>

                </div>}



              <div style={standThemeColorStyle} className={styles.resourcePanelBottom}></div>
              <img className={styles.resourcePanelBottomShadow} src={window.$videoPath + "exhibition/standBottomRightPanelShadow.png"} alt="Shadow" />

              {/** Resource Area */}
              {this.state.showResourceArea ?
                <div>
                  <div style={standThemeColorStyle} className={styles.resourcePanelTop}></div>
                  <img className={styles.resourcePanelTopShadow} src={window.$videoPath + "exhibition/standTopRightPanelShadow.png"} alt="Shadow" />
                  <div className={styles.resourceArea}
                    onClick={() => this.setShowResourceAreaModal(true)}>
                    <img className={styles.resourceAreaIcon} src={window.$videoPath + "exhibition/icons/resource.png"} alt="Resource Area" />
                    <h2 className={styles.resourceAreaTitleText} style={panelTextColorStyle}>Find Out More</h2>
                    <h2 className={styles.resourceAreaSubtitleText} style={panelTextColorStyle}>Click to view and download</h2>
                  </div>
                </div> : null}

              {/** Contact Area */}
              <div className={styles.contactArea}>
                <h2 className={styles.contactAreaTitleText} style={panelTextColorStyle}>Contact</h2>
                <div className={styles.contactOptionsDiv}>
                  {this.state.showQAndAArea ?
                    <div className={styles.contactOptionDiv}
                      onClick={() => this.setShowQuestionsModal(true)}>
                      <img className={styles.contactOptionIcon} src={window.$videoPath + "exhibition/icons/question.png"} alt="Q&A" />
                      <h2 className={styles.contactOptionText} style={panelTextColorStyle}>Q&A</h2>
                    </div> : null}
                  {this.state.showTextChatArea ?
                    <div className={styles.contactOptionDiv}
                      onClick={() => this.setShowTextChatModal(true)}>
                      <img className={styles.contactOptionIcon} src={window.$videoPath + "exhibition/icons/people.png"} alt="Text Chat" />
                      <h2 className={styles.contactOptionText} style={panelTextColorStyle}>Live Text Chat</h2>
                    </div> : null}
                  {this.state.showLeaveDetailsArea ?
                    <div className={styles.contactOptionDiv}
                      onClick={() => this.setShowLeaveDetailsAreaModal(true)}>
                      <img className={styles.contactOptionIcon} src={window.$videoPath + "exhibition/icons/details.png"} alt="Leave details" />
                      <h2 className={styles.contactOptionText} style={panelTextColorStyle}>Leave Your Details</h2>
                    </div> : null}
                  {this.state.showVideoChatArea ?
                    <Link to={"/roundtableSession/" + this.state.videoChatResourceID + "/exhibitionStand/" + this.props.match.params.exhibitionStandID}>
                      <div className={styles.contactOptionDiv}>
                        <img className={styles.contactOptionIcon} src={window.$videoPath + "exhibition/icons/camera.png"} alt="Leave details" />
                        <h2 className={styles.contactOptionText} style={panelTextColorStyle}>Meet and Greet</h2>
                      </div>
                    </Link> : null}

                </div>
              </div>

              {/** Back to hall, forwards and back */}
              <Link to={"/exhibitionHall/" + this.state.hallNumber}>
                <img
                  className={`${styles.hoverHotspot} ${styles.backToHall}`}
                  src="/hoverShapes/hoverSquare.png"
                  alt="Navigate back to Hall"
                ></img>
              </Link>

              {this.state.nextStandID ?
                <Link to={"/exhibitionStand/" + this.state.nextStandID}>
                  <img
                    className={`${styles.hoverHotspot} ${styles.nextStand}`}
                    src="/hoverShapes/hoverSquare.png"
                    alt="Navigate to next stand"
                  ></img>
                </Link> : null}

              {this.state.previousStandID ?
                <Link to={"/exhibitionStand/" + this.state.previousStandID}>
                  <img
                    className={`${styles.hoverHotspot} ${styles.previousStand}`}
                    src="/hoverShapes/hoverSquare.png"
                    alt="Navigate to previous stand"
                  ></img>
                </Link> : null}
            </div>
        }
      </div>
    );
  }
}
