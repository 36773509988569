import React, { Component } from "react";
import { Link } from "react-router-dom";
import dompurify from "dompurify";
import RoundtablesMobile from "./RoundtablesMobile";
import styles from "./VisualRoundtables.module.scss";

export default class Roundtables extends Component {
  constructor() {
    super();
    //Set default message
    this.state = {
      roundtables: [],
    };
  }

  componentDidMount () {
    this.getRoundtables();
  }

  getRoundtables () {
    fetch("/api/getRoundtablesList")
      .then((res) => res.json())
      .then((data) => this.setState({ roundtables: data }));
  }

  sanitizeCustomField = (customField) => {
    return { __html: dompurify.sanitize(customField) };
  };

  roundtablesGrid = (selectedType) => {
    const roundtablesFromCategory = this.state.roundtables.filter(
      (roundtable) => roundtable.roundtableType == selectedType
    );

    return roundtablesFromCategory.map((roundtable, index) => {
      return (
        <Link to={"/roundtableSession/" + roundtable._id}>
          <div className={styles.roundtableItem}>
            <img
              className={styles.roundtablesTableBackground}
              src={window.$videoPath + "icons/roundtableAssets/roundtable4Table.png"}
              alt="Background"
            ></img>

            <div className={styles.tableText}>
              <h1 className={styles.tableNumber}>TABLE {index + 1}</h1>
              <h1 className={styles.tableName}>{roundtable.tableName}</h1>
            </div>

            <div className={styles.lowerRoundtableArea}>

            </div>
          </div>
        </Link>
      );
    });
  };


  render () {
    return (
      <div>
        {this.props.showMobile ?
          <RoundtablesMobile roundtables={this.state.roundtables} />
          :
          <>
            <div className={styles.masterPageDiv}>
              <img
                className="imageNavBackground"
                src={window.$videoPath + "demoVenues/warehouse/altNetworking4Roundtables.jpg"}
                alt="Background"
              ></img>
              <img
                className="imageNavBackground"
                src={window.$videoPath + "demoVenues/warehouse/altNetworking4RoundtablesOverlay.png"}
                alt="Background"
              ></img>

              <div className={styles.roundtablesTitleArea}>
                <Link to={"/networking4"}>
                  <img
                    className={styles.roundtablesBack}
                    src={window.$videoPath + "icons/roundtableAssets/roundtable4Back.png"}
                    alt="Background"
                  ></img>
                </Link>
                <img
                  className={styles.roundtablesTitleText}
                  src={window.$videoPath + "icons/roundtableAssets/roundtable4Title.png"}
                  alt="Background"
                ></img>

              </div>

              <h1 className={styles.roundtablesInfo}>
                To join a face-to-face Roundtable, select your preferred topic
              </h1>

              <section className={styles.profileSegmentsArea}>

                <h1 className={styles.roundtablesSectionTitle}>
                  Section 1
                </h1>
                <section className={styles.profileSegmentsGroup}>
                  {this.roundtablesGrid(10)}
                </section>
                <h1 className={styles.roundtablesSectionTitle}>
                  Section 2
                </h1>
                <section className={styles.profileSegmentsGroup}>
                  {this.roundtablesGrid(11)}
                </section>
              </section>
            </div>
          </>
        }
      </div>
    );
  }
}
