import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./Message.css";

class Message extends Component {
  static propTypes = {
    author: PropTypes.string,
    body: PropTypes.string.isRequired,
    me: PropTypes.bool,
    color: PropTypes.string,
  };

  render () {
    const classes = classNames("Message", {
      log: !this.props.author,
      me: this.props.me,
    });

    return (
      <div className={classes}>
        {this.props.author && (
          <span style={{ color: this.props.color }} className="author">{this.props.author}:</span>
        )}
        <span style={{ color: this.props.color }}>{this.props.body}</span>
      </div>
    );
  }
}

export default Message;
