import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Image, Transformation } from "cloudinary-react";
import { requestPoints } from "./../helpers/requestPoints.js";
import PresentersGalleryModal from "./PresentersGalleryModal.js";
import styles from "./Gallery.module.scss";

export default class PresentersGallery extends Component {
  constructor() {
    super();
    //Set default message
    this.state = {
      galleryContent: [],
      searchString: "",
      showPresenterModal: false,
      selectedPresenter: null,
    };
  }

  componentDidMount() {
    // Hide corner menu
    this.props.setCornerMenuStatus(3);
    this.loadCurrentDatabaseContent();
  }

  componentWillUnmount() {
    // Set corner menu to displayed again
    this.props.setCornerMenuStatus(1);
  }

  componentDidUpdate() {
    // Double check corner menu status is correct
    if (this.props.cornerMenuStatus !== 3) {
      console.log(this.props.cornerMenuStatus);
      this.props.setCornerMenuStatus(3);
    }
  }

  loadCurrentDatabaseContent() {
    fetch("/api/getPresenterGalleryContent")
      .then((res) => res.json())
      .then((data) => this.setState({ galleryContent: data }));
  }

  handleChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handlePresenterModalClose = () => {
    this.loadCurrentDatabaseContent();
    this.setState({ showPresenterModal: false });
  };

  // Called when user clicks request meeting button on another's profile
  viewPresenter = (user) => {
    this.loadCurrentDatabaseContent();
    this.setState({
      selectedPresenter: user,
      showPresenterModal: true,
    });
    requestPoints("viewPresenter", user._id);
  };

  render() {
    const userGalleryFiltered = this.state.galleryContent.filter(
      (user) =>
        user.firstName
          .toLowerCase()
          .includes(this.state.searchString.toLowerCase()) ||
        user.surname
          .toLowerCase()
          .includes(this.state.searchString.toLowerCase()) ||
        user.company
          .toLowerCase()
          .includes(this.state.searchString.toLowerCase()) ||
        user.bio
          .toLowerCase()
          .includes(this.state.searchString.toLowerCase()) ||
        user.website
          .toLowerCase()
          .includes(this.state.searchString.toLowerCase()) ||
        user.talkTitle
          .toLowerCase()
          .includes(this.state.searchString.toLowerCase()) ||
        user.talkAbstract
          .toLowerCase()
          .includes(this.state.searchString.toLowerCase())
    );

    const userGallery = userGalleryFiltered.map((user) => {
      return (
        <div
          key={user._id}
          className={styles.userContainer}
          onClick={() => this.viewPresenter(user)}
        >
          <div className={styles.profilePictureArea}>
            <img
              className={styles.profilePictureBackground}
              src={window.$videoPath + "icons/profilePictureBackground.png"}
              alt="Detail"
            />
            <Image
              className={styles.profilePicture}
              cloudName="oijqec973f1nf"
              publicId={user.userProfilePictureID + ".png"}
              width="180"
              height="180"
              secure={true}
            >
              <Transformation
                height="180"
                width="180"
                gravity="face"
                crop="fill"
                radius="max"
              />
            </Image>
          </div>

          <div className={styles.userNameContainer}>
            <p className={styles.userNameText}>
              {user.firstName}<br />{user.surname}
              <br />
              {/**<span style={{ color: "#1a81b7" }}>Speaker</span>*/}
            </p>
          </div>
          <img
            className={styles.detailButton}
            src={window.$videoPath + "icons/galleryInfo.png"}
            alt="Detail"
          />
        </div>
      );
    });

    return (
      <>

        <PresentersGalleryModal
          showPresenterModal={this.state.showPresenterModal}
          handlePresenterModalClose={this.handlePresenterModalClose}
          selectedPresenter={this.state.selectedPresenter}
        />

        <div className={styles.galleryBackgroundDiv}>
          {/**<img
            className="imageNavBackground"
            src={window.$videoPath + "background.png"}
            alt="Background"
          ></img>*/}
        </div>

        <div className={styles.tabBar}>
          <Link to={"/lobby"} className={styles.backLink}>
            <div className={styles.tabBackDiv}>
              <img
                className={styles.backArrow}
                src={window.$videoPath + "icons/backArrow.png"}
                alt="Back"
              />
              Back to Lobby
            </div>
          </Link>
        </div>

        <div className={styles.titleAndSearchBar}>
          <h1 className={styles.pageTitle}>
            <strong>SPEAKERS</strong>
          </h1>
          <div className={styles.searchTitleArea}>
            <p className={styles.pageInstruction}>Search for speakers and sessions</p>
          </div>
          <input
            className={styles.searchBox}
            type="text"
            name="searchString"
            placeholder="Search"
            value={this.state.searchString}
            onChange={this.handleChange}
            style={{ backgroundImage: 'url(' + window.$videoPath + 'icons/searchIcon.png)', backgroundImageWidth: '100%' }}
          />
        </div>

        <section className={styles.profileSegmentsArea}>{userGallery}</section>
      </>
    );
  }
}
